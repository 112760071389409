import { memo } from "react";
import { compose } from "ramda";
import { connect } from "react-redux";
import * as reducers from "../../../reducers";
import * as actions from "../../../reducers/actions";
import DeleteReleaseNoteModal from ".";

const mapStateToProps = (state) => ({
  deleteReleaseNoteStatus: reducers.getDeleteReleaseNote(state),
});

const mapDispatchToProps = {
  deleteReleaseNote: actions.deleteGAQReleaseNoteEffect,
  resetReleaseNoteStatus: actions.resetDeleteGAQReleaseNoteEffect,
};

export default compose(
  memo,
  connect(mapStateToProps, mapDispatchToProps)
)(DeleteReleaseNoteModal);
