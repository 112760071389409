import { combineReducers } from "redux";
import countrySettingsReducer, * as countrySettings from "./country-settings";
import connectorsOverviewReducer, * as connectorsOverview from "./connectors-overview";
import releasesReducer, * as releases from "./releases";
import rolloutCountriesReducer, * as rolloutCountries from "./rollout-countries";
import analyticsReducer, * as analytics from "./analytics";

export default combineReducers({
  analytics: analyticsReducer,
  countrySettings: countrySettingsReducer,
  connectorsOverview: connectorsOverviewReducer,
  releases: releasesReducer,
  rolloutCountries: rolloutCountriesReducer,
});

export const getCountryConfiguration = (state, country, profile) =>
  countrySettings.getCountryConfiguration(
    state.countrySettings,
    country,
    profile
  );

export const getCountrySettings = (state, country) =>
  countrySettings.getCountrySettings(state.countrySettings, country);

export const getConnectorsOverviewConfig = (state) =>
  connectorsOverview.getConnectorsOverviewConfig(state.connectorsOverview);

export const getConnectorsOverviewReleaseDates = (state) =>
  connectorsOverview.getConnectorsOverviewReleaseDates(
    state.connectorsOverview
  );

export const getCountryField = (state) =>
  countrySettings.getCountryField(state.countrySettings);

export const getDraggedBusinessUnit = (state) =>
  countrySettings.getDraggedBusinessUnit(state.countrySettings);

export const getIsEditMode = (state) =>
  countrySettings.getIsEditMode(state.countrySettings);

export const getReleasedCountries = (state) =>
  countrySettings.getReleasedCountries(state.countrySettings);

export const getLastSelectedCountry = (state) =>
  countrySettings.getLastSelectedCountry(state.countrySettings);

export const getBusinessUnits = (state) =>
  countrySettings.getBusinessUnits(state.countrySettings);

export const getReleases = (state) => releases.get(state.releases);

export const getRolloutCountries = (state) =>
  rolloutCountries.get(state.rolloutCountries);

export const getAnalyticsData = (state) => analytics.getData(state.analytics);

export const getAnalyticsFilter = (state) =>
  analytics.getFilter(state.analytics);
