import { compose } from "ramda";
import {
  DELETE_GAQ_RELEASE_NOTE,
  DELETE_GAQ_RELEASE_NOTE_SUCCESS,
  DELETE_GAQ_RELEASE_NOTE_FAILURE,
  RESET_DELETE_GAQ_RELEASE_NOTE,
} from "../../../../actions";
import RD from "../../../../utils/functional/remote-data";
import * as reducers from "../../..";

const deleteReleaseNote = () => ({
  payload: {},
  type: DELETE_GAQ_RELEASE_NOTE,
});

const deleteReleaseNoteSuccess = (payload) => ({
  payload,
  type: DELETE_GAQ_RELEASE_NOTE_SUCCESS,
});

const deleteReleaseNoteFailure = (error) => ({
  error: true,
  payload: error,
  type: DELETE_GAQ_RELEASE_NOTE_FAILURE,
});

const resetDeleteReleaseNote = () => ({
  payload: {},
  type: RESET_DELETE_GAQ_RELEASE_NOTE,
});

export const deleteGAQReleaseNoteEffect =
  (id) =>
  (dispatch, getState, { api }) => {
    dispatch(deleteReleaseNote());

    const accessToken = RD.withDefault("", reducers.getLoggedUser(getState()));

    api
      .deleteGAQReleaseNote(id, accessToken)
      .then((res) => res.status !== 204 && Promise.reject())
      .then(compose(dispatch, deleteReleaseNoteSuccess))
      .catch(compose(dispatch, deleteReleaseNoteFailure));
  };

export const resetDeleteGAQReleaseNoteEffect = () => (dispatch) => {
  dispatch(resetDeleteReleaseNote());
};
