import { identity } from "ramda";
import { TOGGLE_GAQ_ANALYTICS_BU_FILTER } from "../../../../../actions";

const INITIAL_STATE = [];

const reducer = (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case TOGGLE_GAQ_ANALYTICS_BU_FILTER:
      return state.includes(payload)
        ? state.filter((f) => f !== payload)
        : [...state, payload];

    default:
      return state;
  }
};

export default reducer;

export const get = identity;
