import COPY from "../assets/copy/index.json";

export const CURRENT_DATE = Date.now();

export const MESSAGE_DISPLAY_DURATION = 3000;

export const PERMISSIONS = {
  READ_COUNTRY_CONFIG: "read:config",
  WRITE_COUNTRY_CONFIG: "write:config",
  WRITE_RELEASE_NOTES: "write:config",
};

export const ROUTES = {
  BROWSER_MATRIX: "/browser-matrix",
  CJ_ROLLOUT_OVERVIEW: "/rollout-progress/customer-journeys",
  CONNECTORS_OVERVIEW: "/frequent-shipment/connectors-overview",
  FS_COUNTRY_CONFIGURATIONS: "/frequent-shipment/country-configuration",
  FS_RELEASE_NOTES: "/frequent-shipment/release-notes",
  FSX_RELEASE_NOTES: "/frequent-shipment-fsx/release-notes",
  FS_RELEASE: "/frequent-shipment/release-note/detail",
  FS: "/frequent-shipment",
  GAQ_COUNTRY_CONFIGURATIONS: "/get-a-quote/country-configuration",
  GAQ_RELEASE_NOTES: "/get-a-quote/release-notes",
  GAQ_RELEASE_NOTES_ADMIN: "/get-a-quote/release-notes-admin",
  GAQ_UPDATE_RELEASE_NOTE: "/get-a-quote/release-notes-admin/update",
  GAQ_ADD_RELEASE_NOTE: "/get-a-quote/release-notes-admin/add",
  GAQ: "/get-a-quote",
  SHIP_NOW: "/ship-now",
  SHIP_NOW_RELEASE_NOTES: "/ship-now/release-notes",
  HOMEPAGE_ROLLOUT_OVERVIEW: "/rollout-progress/homepage",
  ROLLOUT_OVERVIEW: "/rollout-progress",
  PROJECT_OVERVIEW: "/rollout-progress/project-overview",
  SIGN_IN: "/sign-in",
};

export const SESSION_ACCESS_KEY = "ut";

export const PRODUCTS = {
  FREIGHT: "FREIGHT",
  MAIL: "MAIL",
  PARCEL: "PARCEL",
};

export const ROLLOUT_TRACKS = {
  CUSTOMER_JOURNEYS: "CUSTOMER_JOURNEYS",
  DHL_COM: "DHL_COM",
  DIGITAL_EXPERIENCE_PLATFORM: "DIGITAL_EXPERIENCE_PLATFORM",
};

export const ROLLOUT_DISPLAY_MODES = {
  MONTH_MODE: "MONTH_MODE",
  YEAR_MODE: "YEAR_MODE",
};

export const ROLLOUT_MONTHS_TO_DISPLAY = {
  MONTH_MODE: 4,
  YEAR_MODE: 12,
};

export const ROLLOUT_MONTHS_PER_STEP = {
  MONTH_MODE: 1,
  YEAR_MODE: 12,
};

export const ROLLOUT_URL_FORMAT = {
  MONTH_MODE: "MM-Y",
  YEAR_MODE: "Y",
};

export const APPLICATIONS = {
  ALL: "all",
  FREQUENT_SHIPMENT: "frequent-shipment",
  FREQUENT_SHIPMENT_FSX: "frequent-shipment-fsx",
  GET_A_QUOTE: "get-a-quote",
  SHIP_NOW: "ship-now",
};

export const HOMEPAGE_VERSIONS = {
  ALL: "all",
  FULL: "full",
  LIGHT: "light",
};

export const REGIONS = {
  DOMESTIC: "DOMESTIC",
  GLOBAL: "GLOBAL",
  REGIONAL: "REGIONAL",
};

export const THIRD_QUESTION = {
  STANDARD: "STANDARD",
  EXPEDITED: "EXPEDITED",
};

export const RELEASE_TYPES = {
  COUNTRY_ROLLOUT: "COUNTRY_ROLLOUT",
  FEATURE_RELEASE: "FEATURE_RELEASE",
  V2_ROLLOUT: "V2_ROLLOUT",
  CANARY_RELEASE: "CANARY_RELEASE",
};

export const GAQ_RELEASE_TYPES_COPY = {
  ALL: "Show All",
  COUNTRY_ROLLOUT: "Country Rollout",
  FEATURE_RELEASE: "Feature Release",
  CANARY_RELEASE: "Canary Release",
};

export const RELEASE_NOTE_ADMIN_MODE = {
  ADD: "ADD",
  UPDATE: "UPDATE",
};

export const SORT_DIRECTIONS = {
  ASC: "asc",
  DESC: "desc",
};

export const ROLLOUT_SORT_PROPERTIES = {
  DATE: "date",
  COUNTRY: "country",
  COVERAGE: "coverage",
};

export const CONNECTORS_OVERVIEW_SORT_PROPERTIES = {
  DATE: "date",
  COUNTRY: "country",
};

export const ALLOWED_CONFIGURATION_QUALIFIERS = [
  "FS",
  "FSX",
  "QUICK_ECS",
  "FSDEC",
];

export const FS_COUNTRIES_AND_PROFILES = {
  AE: ["FSX", "FSDEC"],
  AF: ["FSX", "FSDEC"],
  AL: ["FSX", "FSDEC"],
  AO: ["FSX", "FSDEC"],
  AR: ["FSX", "FSX-SMEPARTNERSHIP", "FSDEC"],
  AT: ["FSX", "FSDEC"],
  AU: ["FSX", "FSDEC"],
  BA: ["FSX", "FSDEC"],
  BB: ["FSX", "FSDEC"],
  BD: ["FSX", "FSDEC"],
  BE: ["FSX", "FSDEC"],
  BG: ["FSX", "FSDEC"],
  BH: ["FSX", "FSDEC"],
  BJ: ["FSX", "FSDEC"],
  BM: ["FSX", "FSX-DHLFORBUSINESS", "FSDEC"],
  BN: ["FSX", "FSX-DHLFORBUSINESS", "FSDEC"],
  BO: ["FSX", "FSDEC"],
  BR: ["FSX", "FSDEC"],
  BS: ["FSX", "FSX-DHLFORBUSINESS", "FSDEC"],
  BT: ["FSX", "FSX-DHLFORBUSINESS", "FSDEC"],
  BW: ["FSX", "FSDEC"],
  BZ: ["FSX", "FSX-DHLFORBUSINESS", "FSDEC"],
  CA: ["FSX", "FSDEC"],
  CD: ["FSX", "FSDEC"],
  CG: ["FSX", "FSDEC"],
  CH: ["FSX", "FSDEC"],
  CI: ["FSX", "FSDEC"],
  CM: ["FSX", "FSDEC"],
  CN: ["FSX", "FSDEC"],
  CO: ["FSX", "FSX-SMEPARTNERSHIP", "FSDEC"],
  CL: ["FSX", "FSX-SMEPARTNERSHIP", "FSDEC"],
  CR: ["FSX", "FSDEC"],
  CY: ["FSX", "FSDEC"],
  CZ: ["FSX", "FSDEC"],
  DE: ["FSX", "FSDEC"],
  DK: ["FSX", "FSDEC"],
  DO: ["FSX", "FSX-DHLFORBUSINESS", "FSDEC"],
  DZ: ["FSX", "FSDEC"],
  EC: ["FSX", "FSDEC"],
  EE: ["FSX", "FSDEC"],
  EG: ["FSX", "FSDEC"],
  ET: ["FSX", "FSDEC"],
  FI: ["FSX", "FSDEC"],
  FJ: ["FSX", "FSDEC"],
  FR: ["FSX", "FSDEC"],
  FS: ["FSX", "FSDEC"],
  GA: ["FSX", "FSDEC"],
  GB: ["FSX", "FSX-MYDHLPLUS", "FSDEC"],
  GE: ["FSX", "FSX-DHLFORBUSINESS", "FSDEC"],
  GF: ["FSX", "FSDEC"],
  GH: ["FSX", "FSDEC"],
  GM: ["FSX", "FSDEC"],
  GP: ["FSX", "FSDEC"],
  GQ: ["FSX", "FSDEC"],
  GR: ["FSX", "FSDEC"],
  GT: ["FSX", "FSDEC"],
  HK: ["FSX", "FSDEC"],
  HN: ["FSX", "FSDEC"],
  HR: ["FSX", "FSDEC"],
  HT: ["FSX", "FSDEC"],
  HU: ["FSX", "FSDEC"],
  ID: ["FSX", "FSDEC"],
  IE: ["FSX", "FSDEC"],
  IL: ["FSX", "FSDEC"],
  IN: ["FSX", "FSDEC"],
  IQ: ["FSX", "FSDEC"],
  IR: ["FSX", "FSDEC"],
  IS: ["FSX", "FSDEC"],
  IT: ["FSX", "FSDEC"],
  JM: ["FSX", "FSX-DHLFORBUSINESS", "FSDEC"],
  JO: ["FSX", "FSX-DHLFORBUSINESS", "FSDEC"],
  JP: ["FSX", "FSDEC"],
  KE: ["FSX", "FSDEC"],
  KH: ["FSX", "FSDEC"],
  KR: ["FSX", "FSDEC"],
  KW: ["FSX", "FSDEC"],
  KY: ["FSX", "FSX-DHLFORBUSINESS", "FSDEC"],
  KZ: ["FSX", "FSDEC"],
  LA: ["FSX", "FSX-DHLFORBUSINESS", "FSDEC"],
  LB: ["FSX", "FSDEC"],
  LK: ["FSX", "FSDEC"],
  LS: ["FSX", "FSDEC"],
  LT: ["FSX", "FSDEC"],
  LU: ["FSX", "FSDEC"],
  LV: ["FSX", "FSDEC"],
  MA: ["FSX", "FSDEC"],
  MG: ["FSX", "FSDEC"],
  MK: ["FSX", "FSDEC"],
  ML: ["FSX", "FSDEC"],
  MM: ["FSX", "FSDEC"],
  MN: ["FSX", "FSX-DHLFORBUSINESS", "FSDEC"],
  MO: ["FSX", "FSDEC"],
  MQ: ["FSX", "FSX-DHLFORBUSINESS", "FSDEC"],
  MT: ["FSX", "FSDEC"],
  MU: ["FSX", "FSDEC"],
  MV: ["FSX", "FSX-DHLFORBUSINESS", "FSDEC"],
  MW: ["FSX", "FSDEC"],
  MX: ["FSX", "FSX-MYDHLPLUS", "FSDEC"],
  MY: ["FSX", "FSDEC"],
  MZ: ["FSX", "FSDEC"],
  NA: ["FSX", "FSDEC"],
  NG: ["FSX", "FSDEC"],
  NI: ["FSX", "FSDEC"],
  NO: ["FSX", "FSDEC"],
  NP: ["FSX", "FSX-DHLFORBUSINESS", "FSDEC"],
  NZ: ["FSX", "FSDEC"],
  OM: ["FSX", "FSDEC"],
  PA: ["FSX", "FSDEC"],
  PE: ["FSX", "FSX-SMEPARTNERSHIP", "FSDEC"],
  PG: ["FSX", "FSDEC"],
  PH: ["FSX", "FSX-MYDHLPLUS", "FSDEC"],
  PK: ["FSX", "FSDEC"],
  PL: ["FSX", "FSDEC"],
  PT: ["FSX", "FSDEC"],
  PY: ["FSX", "FSX-DHLFORBUSINESS", "FSDEC"],
  QA: ["FSX", "FSDEC"],
  RE: ["FSX", "FSX-DHLFORBUSINESS", "FSDEC"],
  RO: ["FSX", "FSDEC"],
  RS: ["FSX", "FSDEC"],
  SA: ["FSX", "FSDEC"],
  SC: ["FSX", "FSDEC"],
  SE: ["FSX", "FSDEC"],
  SG: ["FSX", "FSX-MYDHLPLUS", "FSDEC"],
  SI: ["FSX", "FSDEC"],
  SK: ["FSX", "FSDEC"],
  SL: ["FSX", "FSDEC"],
  SN: ["FSX", "FSDEC"],
  SV: ["FSX", "FSDEC"],
  SY: ["FSX", "FSDEC"],
  SZ: ["FSX", "FSDEC"],
  TH: ["FSX", "FSDEC"],
  TL: ["FSX", "FSX-DHLFORBUSINESS", "FSDEC"],
  TR: ["FSX", "FSDEC"],
  TT: ["FSX", "FSX-DHLFORBUSINESS", "FSDEC"],
  TW: ["FSX", "FSDEC"],
  TZ: ["FSX", "FSDEC"],
  UA: ["FSX", "FSDEC"],
  UG: ["FSX", "FSDEC"],
  UY: ["FSX", "FSX-DHLFORBUSINESS", "FSDEC"],
  VE: ["FSX", "FSDEC"],
  VG: ["FSX", "FSX-DHLFORBUSINESS", "FSDEC"],
  VN: ["FSX", "FSDEC"],
  YE: ["FSX", "FSDEC"],
  ZA: ["FSX", "FSX-MYDHLPLUS", "FSDEC"],
  ZM: ["FSX", "FSDEC"],
  ZW: ["FSX", "FSDEC"],
  US: [
    "FSX",
    "FSX-DISCOVER",
    "FSX-TAS",
    "FSX-MYDHLPLUS",
    "FSX-EXPRESSMOBILEAPP",
    "FSX-DEVELOPMENT",
    "FSX-SMEPARTNERSHIP",
    "FSX-GOGREENPLUS",
    "FSDEC",
  ],
};

export const FS_DEFAULT_LEAD_META_VALUES = {
  leadSourceCallback: "FS - Callback",
  leadSourceDefault: "FS",
  sourceType: "DHL.com",
};

export const SHIP_NOW_COUNTRIES = ["AU", "IT", "MX", "NL", "ZA"]
  .map((country) => [country, COPY[`Country.${country}`]])
  .sort(([, value1], [, value2]) => value1.localeCompare(value2));

export const FS_COUNTRIES = [
  "AE",
  "AL",
  "AO",
  "AR",
  "AT",
  "AU",
  "BA",
  "BB",
  "BD",
  "BE",
  "BG",
  "BH",
  "BJ",
  "BM",
  "BN",
  "BO",
  "BR",
  "BS",
  "BT",
  "BW",
  "BZ",
  "CA",
  "CD",
  "CG",
  "CH",
  "CI",
  "CL",
  "CM",
  "CN",
  "CO",
  "CR",
  "CY",
  "CZ",
  "DE",
  "DK",
  "DO",
  "DZ",
  "EC",
  "EE",
  "EG",
  "ES",
  "ET",
  "FI",
  "FJ",
  "FR",
  "GA",
  "GB",
  "GF",
  "GH",
  "GM",
  "GP",
  "GQ",
  "GR",
  "GT",
  "HK",
  "HN",
  "HR",
  "HU",
  "HT",
  "ID",
  "IE",
  "IL",
  "IN",
  "IQ",
  "IS",
  "IT",
  "JM",
  "JO",
  "JP",
  "KE",
  "KH",
  "KR",
  "KW",
  "KY",
  "KZ",
  "LA",
  "LB",
  "LK",
  "LS",
  "LT",
  "LU",
  "LV",
  "MA",
  "MG",
  "MK",
  "ML",
  "MM",
  "MO",
  "MQ",
  "MT",
  "MU",
  "MV",
  "MW",
  "MX",
  "MY",
  "MZ",
  "NA",
  "NG",
  "NI",
  "NL",
  "NO",
  "NP",
  "NZ",
  "OM",
  "PA",
  "PE",
  "PG",
  "PH",
  "PK",
  "PL",
  "PT",
  "PY",
  "QA",
  "RE",
  "RO",
  "RS",
  "SA",
  "SC",
  "SD",
  "SE",
  "SG",
  "SI",
  "SK",
  "SL",
  "SN",
  "SS",
  "SV",
  "SY",
  "SZ",
  "TH",
  "TL",
  "TR",
  "TT",
  "TW",
  "TZ",
  "UA",
  "UG",
  "US",
  "UY",
  "VE",
  "VG",
  "VN",
  "YE",
  "ZA",
  "ZM",
  "ZW",
]
  .map((country) => [country, COPY[`Country.${country}`]])
  .sort(([, value1], [, value2]) => value1.localeCompare(value2));

export const GAQ_COUNTRIES = [
  "AE",
  "AR",
  "AT",
  "AU",
  "BD",
  "BR",
  "CA",
  "CH",
  "CL",
  "CN",
  "CO",
  "CR",
  "DE",
  "DK",
  "EC",
  "EE",
  "EG",
  "ES",
  "FI",
  "FR",
  "GB",
  "GH",
  "GR",
  "HK",
  "HN",
  "HR",
  "HU",
  "ID",
  "IE",
  "IL",
  "IN",
  "IT",
  "JP",
  "KE",
  "KH",
  "KR",
  "KW",
  "LB",
  "LK",
  "LT",
  "LV",
  "MA",
  "MX",
  "MY",
  "NG",
  "NL",
  "NO",
  "NZ",
  "PA",
  "PE",
  "PH",
  "PK",
  "PL",
  "PT",
  "QA",
  "RO",
  "RS",
  "RU",
  "SA",
  "SG",
  "SI",
  "SV",
  "TH",
  "TR",
  "TW",
  "UG",
  "US",
  "VN",
  "ZA",
]
  .map((country) => [country, COPY[`Country.${country}`]])
  .sort(([, value1], [, value2]) => value1.localeCompare(value2));

export const PHASE_III_COUNTRIES = [
  "BH",
  "BO",
  "BG",
  "HR",
  "EC",
  "EG",
  "EE",
  "GH",
  "HU",
  "IL",
  "KZ",
  "KE",
  "KW",
  "LV",
  "LT",
  "MA",
  "NG",
  "PH",
  "QA",
  "SK",
  "AE",
  "UA",
  "VE",
  "VN",
].reduce((acc, country) => ({ ...acc, [country]: true }), {});

export const PHASE_IV_COUNTRIES = [
  "CR",
  "GT",
  "PA",
  "SI",
  "RS",
  "CY",
  "LU",
  "AO",
  "SV",
  "HN",
  "CI",
  "NI",
  "SN",
  "CM",
  "MZ",
  "MG",
  "GA",
  "ID",
  "BD",
  "LK",
  "LB",
  "KH",
  "IQ",
  "OM",
  "AL",
  "BA",
  "MU",
  "FJ",
  "MK",
  "UG",
  "ET",
  "MM",
  "PG",
  "ZW",
].reduce((acc, country) => ({ ...acc, [country]: true }), {});

export const PHASE_V_COUNTRIES = [
  "BB",
  "BJ",
  "BM",
  "BN",
  "BS",
  "BT",
  "BW",
  "BZ",
  "CD",
  "CG",
  "DO",
  "DZ",
  "GF",
  "GM",
  "GP",
  "GQ",
  "HT",
  "IS",
  "JM",
  "JO",
  "KY",
  "LA",
  "LS",
  "ML",
  "MO",
  "MQ",
  "MT",
  "MV",
  "MW",
  "NA",
  "NP",
  "PY",
  "RE",
  "SC",
  "SD",
  "SL",
  "SS",
  "SY",
  "SZ",
  "TL",
  "TT",
  "TZ",
  "UY",
  "VG",
  "YE",
  "ZM",
].reduce((acc, country) => ({ ...acc, [country]: true }), {});

export const ALL_COUNTRIES = [
  "AD",
  "AE",
  "AF",
  "AG",
  "AI",
  "AL",
  "AM",
  "AO",
  "AR",
  "AS",
  "AT",
  "AU",
  "AW",
  "AX",
  "AZ",
  "BA",
  "BB",
  "BD",
  "BE",
  "BF",
  "BG",
  "BH",
  "BI",
  "BJ",
  "BM",
  "BN",
  "BO",
  "BR",
  "BS",
  "BT",
  "BV",
  "BW",
  "BY",
  "BZ",
  "CA",
  "CC",
  "CD",
  "CF",
  "CG",
  "CH",
  "CI",
  "CK",
  "CL",
  "CM",
  "CN",
  "CO",
  "CR",
  "CU",
  "CV",
  "CX",
  "CY",
  "CZ",
  "DE",
  "DJ",
  "DK",
  "DM",
  "DO",
  "DZ",
  "EC",
  "EE",
  "EG",
  "ER",
  "ES",
  "ET",
  "FI",
  "FJ",
  "FK",
  "FM",
  "FO",
  "FR",
  "GA",
  "GB",
  "GD",
  "GE",
  "GF",
  "GG",
  "GH",
  "GI",
  "GL",
  "GM",
  "GN",
  "GP",
  "GQ",
  "GR",
  "GT",
  "GU",
  "GW",
  "GY",
  "HK",
  "HN",
  "HR",
  "HT",
  "HU",
  "IC",
  "ID",
  "IE",
  "IL",
  "IM",
  "IN",
  "IQ",
  "IR",
  "IS",
  "IT",
  "JE",
  "JM",
  "JO",
  "JP",
  "KE",
  "KG",
  "KH",
  "KI",
  "KM",
  "KN",
  "KP",
  "KR",
  "KV",
  "KW",
  "KY",
  "KZ",
  "LA",
  "LB",
  "LC",
  "LI",
  "LK",
  "LR",
  "LS",
  "LT",
  "LU",
  "LV",
  "LY",
  "MA",
  "MC",
  "MD",
  "ME",
  "MG",
  "MH",
  "MK",
  "ML",
  "MM",
  "MN",
  "MO",
  "MP",
  "MQ",
  "MR",
  "MS",
  "MT",
  "MU",
  "MV",
  "MW",
  "MX",
  "MY",
  "MZ",
  "NA",
  "NC",
  "NE",
  "NF",
  "NG",
  "NI",
  "NL",
  "NO",
  "NP",
  "NR",
  "NU",
  "NZ",
  "OM",
  "PA",
  "PE",
  "PF",
  "PG",
  "PH",
  "PK",
  "PL",
  "PM",
  "PN",
  "PR",
  "PT",
  "PW",
  "PY",
  "QA",
  "RE",
  "RO",
  "RS",
  "RU",
  "RW",
  "SA",
  "SB",
  "SC",
  "SD",
  "SE",
  "SG",
  "SH",
  "SI",
  "SJ",
  "SK",
  "SL",
  "SM",
  "SN",
  "SO",
  "SR",
  "SS",
  "ST",
  "SV",
  "SY",
  "SZ",
  "TC",
  "TD",
  "TG",
  "TH",
  "TJ",
  "TK",
  "TL",
  "TM",
  "TN",
  "TO",
  "TR",
  "TT",
  "TV",
  "TW",
  "TZ",
  "UA",
  "UG",
  "US",
  "UY",
  "UZ",
  "VA",
  "VC",
  "VE",
  "VG",
  "VI",
  "VN",
  "VU",
  "WF",
  "WS",
  "XB",
  "XC",
  "XE",
  "XM",
  "XN",
  "XS",
  "XY",
  "YE",
  "YT",
  "ZA",
  "ZM",
  "ZW",
];

export const EUROPEAN_COUNTRIES = [
  "AT",
  "AL",
  "BA",
  "BE",
  "BG",
  "CY",
  "CZ",
  "DE",
  "DK",
  "EE",
  "ES",
  "FI",
  "FR",
  "GR",
  "HR",
  "HU",
  "IE",
  "IT",
  "LT",
  "LU",
  "LV",
  "MK",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "RS",
  "SE",
  "SI",
  "SK",
];

export const AUTOCOMPLETE_COUNTRIES = [
  "AE",
  "AF",
  "AL",
  "AO",
  "AR",
  "AT",
  "AU",
  "BA",
  "BB",
  "BD",
  "BE",
  "BG",
  "BH",
  "BJ",
  "BM",
  "BN",
  "BO",
  "BR",
  "BS",
  "BT",
  "BW",
  "BY",
  "BZ",
  "CA",
  "CD",
  "CG",
  "CH",
  "CI",
  "CL",
  "CM",
  "CN",
  "CO",
  "CR",
  "CW",
  "CY",
  "CZ",
  "DE",
  "DK",
  "DO",
  "DZ",
  "EC",
  "EE",
  "EG",
  "ES",
  "ET",
  "FI",
  "FJ",
  "FR",
  "GA",
  "GB",
  "GE",
  "GF",
  "GH",
  "GM",
  "GP",
  "GQ",
  "GR",
  "GT",
  "HK",
  "HN",
  "HR",
  "HT",
  "HU",
  "ID",
  "IE",
  "IL",
  "IN",
  "IQ",
  "IR",
  "IS",
  "IT",
  "JM",
  "JO",
  "JP",
  "KE",
  "KH",
  "KR",
  "KW",
  "KY",
  "KZ",
  "LA",
  "LB",
  "LK",
  "LS",
  "LT",
  "LU",
  "LV",
  "MA",
  "MG",
  "MK",
  "ML",
  "MM",
  "MN",
  "MO",
  "MQ",
  "MT",
  "MU",
  "MV",
  "MW",
  "MX",
  "MY",
  "MZ",
  "NA",
  "NG",
  "NI",
  "NL",
  "NO",
  "NP",
  "NZ",
  "OM",
  "PA",
  "PE",
  "PG",
  "PH",
  "PK",
  "PL",
  "PT",
  "PY",
  "QA",
  "RE",
  "RO",
  "RS",
  "RU",
  "SA",
  "SC",
  "SD",
  "SE",
  "SG",
  "SI",
  "SK",
  "SL",
  "SN",
  "SS",
  "SV",
  "SY",
  "SZ",
  "TH",
  "TL",
  "TR",
  "TT",
  "TW",
  "TZ",
  "UA",
  "UG",
  "US",
  "UY",
  "VC",
  "VE",
  "VG",
  "VN",
  "YE",
  "ZA",
  "ZM",
  "ZW",
];

export const AUTOCOMPLETE_LANGUAGES = [
  "bg",
  "cs",
  "da",
  "de",
  "ee",
  "el",
  "en",
  "es",
  "et",
  "fi",
  "fr",
  "hr",
  "hu",
  "it",
  "ja",
  "ko",
  "ll",
  "lt",
  "lv",
  "nl",
  "no",
  "pl",
  "pt",
  "ro",
  "ru",
  "sk",
  "sl",
  "sr",
  "sv",
  "th",
  "tr",
  "uk",
  "vi",
  "zh-TW",
  "zh",
];

export const RELEASE_STATUS = {
  ALL_APPS_RELEASED: "all-apps-released",
  NOT_PLANNED: "not-planned",
  ONE_APP_PLANNED: "one-app-planned",
  ONE_APP_RELEASED: "one-apps-released",
  PARTIALLY_RELEASED: "partially-released",
  PLANNED: "planned",
  RELEASED: "released",
};

export const HOMEPAGE_RELEASE_STATUS = {
  FULL_RELEASED: "full-released",
  LIGHT_RELEASED: "light-released",
  PLANNED: "planned",
};

export const MAP_DISPLAY_MODES = {
  REGION: "region",
  MARKERS: "markers",
};

export const MODALS = {
  CJ_MAP_REGION: "CJ_MAP_REGION",
  EDIT_BUSINESS_UNIT: "EDIT_BUSINESS_UNIT",
  HOMEPAGE_MAP_REGION: "HOMEPAGE_MAP_REGION",
  SHARE: "SHARE",
  PROJECT_OVERVIEW_DETAILS: "PROJECT_OVERVIEW_DETAILS",
  DELETE_RELEASE_NOTE: "DELETE_RELEASE_NOTE",
};

export const MAP_REGIONS = {
  AFRICA_EASTERN: "014",
  AFRICA_MIDDLE: "017",
  AFRICA_NOTHERN: "015",
  AFRICA_SOUTHERN: "018",
  AFRICA_WESTERN: "011",
  AFRICA: "002",
  AMERICA_CARIBBEAN: "029",
  AMERICA_CENTRAL: "013",
  AMERICA_NORTH: "021",
  AMERICA_SOUTH: "005",
  AMERICA: "019",
  ASIA_CENTRAL: "143",
  ASIA_EASTERN: "030",
  ASIA_SOUTH_EASTERN: "035",
  ASIA_SOUTHERN: "034",
  ASIA_WESTERN: "145",
  ASIA: "142",
  EUROPE_EASTERN: "151",
  EUROPE_NOTHERN: "154",
  EUROPE_SOUTHERN: "039",
  EUROPE_WESTERN: "155",
  EUROPE: "150",
  OCEANIA_AUSTRALIA: "053",
  OCEANIA_MELANESIA: "054",
  OCEANIA_MICRONESIA: "057",
  OCEANIA_POLYNESIA: "061",
  OCEANIA: "009",
  WORLD: "world",
};

export const DHL_REGIONS = {
  AMERICAS: "Americas",
  APAC: "APAC",
  EUROPE_AND_MEA: "Europe & MEA",
};

export const UNIT_SYSTEMS = {
  METRIC: "Metric",
  IMPERIAL: "Imperial",
};

export const CONNECTORS_BUSINESS_UNITS = ["EXP", "PNP", "PAR", "DGF"];

export const CONNECTORS = [
  "callBack",
  "callBackRemote",
  "contactForm",
  "liveChat",
  "selfRegister",
  "callIn",
  "openAnAccount",
];
