/* eslint-disable no-underscore-dangle */

import { curry } from "ramda";

const APPLICATION_TYPES = {
  ALL: "All",
  FREQUENT_SHIPMENT: "Frequent Shipment",
  GET_A_QUOTE: "Get A Quote",
};

const NOOP = Function.prototype;

const Applications = {
  getAQuote: (data) => ({
    _data: data,
    tag: APPLICATION_TYPES.GET_A_QUOTE,
  }),

  frequentShipment: (data) => ({
    _data: data,
    tag: APPLICATION_TYPES.FREQUENT_SHIPMENT,
  }),

  all: (data) => ({
    _data: data,
    tag: APPLICATION_TYPES.ALL,
  }),

  case: curry((caseObj, applications) => {
    const { frequentShipment, getAQuote, all, _ = NOOP } = caseObj;

    if (frequentShipment && Applications.isFrequentShipment(applications)) {
      return frequentShipment(applications._data);
    }

    if (getAQuote && Applications.isGetAQuote(applications)) {
      return getAQuote(applications._data);
    }

    if (all && Applications.isAll(applications)) {
      return all(applications._data);
    }

    return _();
  }),

  map: curry((mapFun, applications) =>
    Applications.case({
      all: (data) => Applications.all(mapFun(data)),
      frequentShipment: (data) => Applications.frequentShipment(mapFun(data)),
      getAQuote: (data) => Applications.getAQuote(mapFun(data)),
    })(applications)
  ),

  isAll: (applications) => applications.tag === APPLICATION_TYPES.ALL,

  isGetAQuote: (applications) =>
    applications.tag === APPLICATION_TYPES.GET_A_QUOTE,

  isFrequentShipment: (applications) =>
    applications.tag === APPLICATION_TYPES.FREQUENT_SHIPMENT,

  getValue: (applications) => applications._data,
};

export default Applications;
