import { memo } from "react";
import { connect } from "react-redux";
import * as reducers from "../../reducers";
import AsyncRoute from ".";

const mapStateToProps = (state) => ({
  loggedUserPermissions: reducers.getLoggedUserPermissions(state),
});

export default memo(connect(mapStateToProps)(AsyncRoute));
