import { combineReducers } from "redux";
import {
  flatten,
  uniq,
  map,
  propEq,
  pluck,
  filter,
  compose,
  equals,
  prop,
  reduce,
  always,
  values,
} from "ramda";
import { createSelector } from "reselect";
import customerJourneysReducer, * as customerJourneys from "./customer-journeys";
import modalsReducer, * as modals from "./modals";
import countriesReducer, * as countries from "./countries";
import homepageReducer, * as homepage from "./homepage";
import metaReducer, * as meta from "./meta";
import projectOverviewReducer, * as projectOverview from "./project-overview";
import D from "../utils/functional/draggable";
import RD from "../utils/functional/remote-data";
import Applications from "../utils/functional/applications";
import {
  APPLICATIONS,
  CURRENT_DATE,
  HOMEPAGE_VERSIONS,
  HOMEPAGE_RELEASE_STATUS,
  MAP_DISPLAY_MODES,
  MAP_REGIONS,
  RELEASE_STATUS,
  RELEASE_TYPES,
} from "../utils/constants";
import {
  PARTIALLY_RELEASED_COLOR,
  PLANNED_RELEASE_COLOR,
  RELEASED_COLOR,
} from "../styles";

const aggregateStatus = reduce(
  (acc, cur) => ({
    ...acc,
    [cur.status]: acc[cur.status] ? acc[cur.status] + 1 : 1,
  }),
  {}
);

const filterPastEvents = filter((r) => r.date <= CURRENT_DATE);

const getRolloutsForCountry = (country, app) => {
  const countryForApplication = app.find(propEq("country", country));

  return countryForApplication
    ? {
        releases: countryForApplication.releases,
        status: countryForApplication.status,
        analytics: countryForApplication.analytics,
        v2Releases: countryForApplication.v2Releases,
      }
    : {
        releases: [],
        status: RELEASE_STATUS.NOT_PLANNED,
      };
};

const getMostRelevantStatus = (...args) => {
  if (args.filter(equals(RELEASE_STATUS.RELEASED)).length === args.length) {
    return RELEASE_STATUS.ALL_APPS_RELEASED;
  }
  if (
    args.includes(RELEASE_STATUS.PARTIALLY_RELEASED) ||
    args.includes(RELEASE_STATUS.RELEASED)
  ) {
    return RELEASE_STATUS.ONE_APP_RELEASED;
  }

  if (args.includes(RELEASE_STATUS.PLANNED)) {
    return RELEASE_STATUS.ONE_APP_PLANNED;
  }

  return RELEASE_STATUS.NOT_PLANNED;
};

const getCodeForStatus = (status) => {
  switch (status) {
    case HOMEPAGE_RELEASE_STATUS.PLANNED:
    case RELEASE_STATUS.PLANNED:
    case RELEASE_STATUS.ONE_APP_PLANNED:
      return 0;

    case HOMEPAGE_RELEASE_STATUS.LIGHT_RELEASED:
    case RELEASE_STATUS.PARTIALLY_RELEASED:
    case RELEASE_STATUS.ONE_APP_RELEASED:
      return 1;

    case HOMEPAGE_RELEASE_STATUS.FULL_RELEASED:
    case RELEASE_STATUS.RELEASED:
    case RELEASE_STATUS.ALL_APPS_RELEASED:
      return 2;

    default:
      return 0;
  }
};

const decodeRegionalMapData = (rolledOutCountries) =>
  rolledOutCountries.map(({ country, status }) => [
    country,
    getCodeForStatus(status),
  ]);

const decodeMarkerMapData = (coordinates, rolledOutCountries) =>
  rolledOutCountries.map(({ country, coverage, status }) => {
    const { lat, lng } = coordinates[country];

    return [lat, lng, getCodeForStatus(status), coverage];
  });

const filterRolloutByRegion = (regions, selectedRegion) =>
  filter(
    (c) =>
      selectedRegion === MAP_REGIONS.WORLD ||
      (regions[c.country] || []).includes(selectedRegion)
  );

const filterRolloutByStatus = (releaseStatus) =>
  filter((c) => releaseStatus.includes(c.status));

const makeMapData = (rolledOutCountry, displayMode, coordinates) => {
  switch (displayMode) {
    case MAP_DISPLAY_MODES.REGION: {
      const header = ["Country", "Type"];
      const data = decodeRegionalMapData(rolledOutCountry);

      return [header, ...data];
    }

    case MAP_DISPLAY_MODES.MARKERS: {
      const header = ["Latitude", "Longitude", "Type", "Coverage"];
      const data = decodeMarkerMapData(coordinates, rolledOutCountry);

      return data.length === 0 ? [["Country", "Type"]] : [header, ...data];
    }

    default:
      return [];
  }
};

const getFSRolloutCountries = (state) =>
  customerJourneys.getFSRolloutCountries(state.customerJourneys);

const getReleasesForCountry = (countryReleases, country) =>
  countryReleases
    .filter((item) => item.countries.some(propEq("country", country)))
    .map((release) => {
      const getLanguages = compose(
        flatten,
        pluck("languages"),
        filter(propEq("country", country))
      );

      return {
        date: release.date,
        languages: getLanguages(release.countries),
      };
    });

const getStatusForLocales = (locales) => {
  const releasedLocales = locales.filter((l) => l.date <= CURRENT_DATE);

  if (locales.length === 0) {
    return RELEASE_STATUS.PLANNED;
  }

  if (releasedLocales.length === 0) {
    return RELEASE_STATUS.PLANNED;
  }

  if (releasedLocales.length === locales.length) {
    return RELEASE_STATUS.RELEASED;
  }

  return RELEASE_STATUS.PARTIALLY_RELEASED;
};

const aggregateCountryReleases =
  (filteredRegion, dhlRegion, coverages) => (rolloutCountries, appReleases) => {
    const countryReleases = appReleases.filter(
      propEq("type", RELEASE_TYPES.COUNTRY_ROLLOUT)
    );

    const V2Releases = appReleases.filter(
      propEq("type", RELEASE_TYPES.V2_ROLLOUT)
    );

    return rolloutCountries
      .filter(
        (country) => !filteredRegion || dhlRegion[country] === filteredRegion
      )
      .map((country) => {
        const releases = getReleasesForCountry(countryReleases, country);
        const v2Releases = getReleasesForCountry(V2Releases, country);
        const status = getStatusForLocales(releases);

        return {
          country,
          coverage: coverages && coverages[country],
          releases,
          status,
          v2Releases,
        };
      });
  };

const reducers = (loggedUser) =>
  combineReducers({
    countries: countriesReducer,
    customerJourneys: customerJourneysReducer,
    homepage: homepageReducer,
    meta: metaReducer(loggedUser),
    modals: modalsReducer,
    projectOverview: projectOverviewReducer,
  });

const filterUniqueFilterBUs = compose(
  uniq,
  flatten,
  map(({ businessUnits }) => businessUnits.map(prop("id"))),
  values
);

export const getLoggedUser = (state) => meta.getLoggedUser(state.meta);

export const getLoggedUserPermissions = createSelector(
  getLoggedUser,
  RD.case({
    success: (token) => JSON.parse(atob(token.split(".")[1])).scope.split(" "),
    _: always([]),
  })
);

export const getFSCountryConfiguration = (state, country, profile) =>
  customerJourneys.getFSCountryConfiguration(
    state.customerJourneys,
    country,
    profile
  );

export const getFSCountrySettings = (state, country) =>
  customerJourneys.getFSCountrySettings(state.customerJourneys, country);

export const getFSConnectorsOverviewConfig = (state) =>
  customerJourneys.getFSConnectorsOverviewConfig(state.customerJourneys);

export const getFSConnectorsOverviewBusinessUnits = createSelector(
  getFSConnectorsOverviewConfig,
  compose(uniq, pluck("businessUnit"), RD.withDefault([]))
);

export const getFSConnectorsOverviewReleaseDates = (state) =>
  customerJourneys.getFSConnectorsOverviewReleaseDates(state.customerJourneys);

export const getFSCountryField = (state) =>
  customerJourneys.getFSCountryField(state.customerJourneys);

export const getFSDraggedBusinessUnit = (state) =>
  customerJourneys.getFSDraggedBusinessUnit(state.customerJourneys);

export const getFSIsEditMode = (state) =>
  customerJourneys.getFSIsEditMode(state.customerJourneys);

export const getBusinessUnits = (state) =>
  customerJourneys.getBusinessUnits(state.customerJourneys);

export const getFSReleasedCountries = (state) =>
  customerJourneys.getFSReleasedCountries(state.customerJourneys);

export const getIsBUBeingDragged = (state, businessUnit, product, region) => {
  const draggedBusinessUnit = getFSDraggedBusinessUnit(state);

  if (!draggedBusinessUnit) {
    return false;
  }

  const isBeingDragged = (draggedId, origin = {}) =>
    draggedId === businessUnit &&
    origin.product === product &&
    origin.region === region;

  return D.case({ _: isBeingDragged }, draggedBusinessUnit);
};

export const getOpenedModal = (state) => modals.getOpenedModal(state.modals);

export const getModalData = (state) => modals.getModalData(state.modals);

export const getIsEditBusinessUnitModalFormValid = (state) =>
  modals.getIsEditBusinessUnitModalFormValid(state.modals);

export const getHomepageReleaseStatusFilter = (state) =>
  homepage.getReleaseStatus(state.homepage);

export const getCJReleaseStatusFilter = (state) =>
  customerJourneys.getCJReleaseStatus(state.customerJourneys);

export const getHomepageMapDisplayModeFilter = (state) =>
  homepage.getMapDisplayMode(state.homepage);

export const getCJMapDisplayModeFilter = (state) =>
  customerJourneys.getCJMapDisplayMode(state.customerJourneys);

export const getHomepageMapRegionFilter = (state) =>
  homepage.getMapRegion(state.homepage);

export const getCJMapRegionFilter = (state) =>
  customerJourneys.getCJMapRegion(state.customerJourneys);

export const getFSReleases = (state) =>
  customerJourneys.getFSReleases(state.customerJourneys);

export const getFSXReleases = (state) =>
  customerJourneys.getFSXReleases(state.customerJourneys);

export const getFSLastSelectedCountry = (state) =>
  customerJourneys.getFSLastSelectedCountry(state.customerJourneys);

export const getGAQCountrySettings = (state, country) =>
  customerJourneys.getGAQCountrySettings(state.customerJourneys, country);

export const getGAQCountryField = (state) =>
  customerJourneys.getGAQCountryField(state.customerJourneys);

export const getGAQLastSelectedCountry = (state) =>
  customerJourneys.getGAQLastSelectedCountry(state.customerJourneys);

export const getGAQReleaseNotes = (state) =>
  compose(RD.withDefault([]))(
    customerJourneys.getGAQReleaseNotes(state.customerJourneys)
  );

export const getGAQRollouts = (state) =>
  compose(
    RD.case({
      success: (data) =>
        data.reduce((rolloutCountries, r) => {
          if (typeof r.countries !== "undefined") {
            r.countries.map((c) => rolloutCountries.push(c.country));
          }
          return [...new Set(rolloutCountries)];
        }, []),
      _: always([]),
    })
  )(customerJourneys.getGAQReleaseNotes(state.customerJourneys));

export const getSNReleases = (state) =>
  customerJourneys.getSNReleases(state.customerJourneys);

export const getCountryCoverages = (state) =>
  countries.getCoverages(state.countries);

export const getCountryRegions = (state) =>
  countries.getRegions(state.countries);

export const getCountryDHLRegion = (state) =>
  countries.getDHLRegion(state.countries);

export const getCountryCoordinates = (state) =>
  countries.getCoordinates(state.countries);

export const getCountryCoordinatesHashMap = (state) =>
  countries.getCoordinatesHashMap(state.countries);

export const getFSPastReleases = createSelector(
  getFSReleases,
  filterPastEvents
);

export const getFSXPastReleases = createSelector(
  getFSXReleases,
  filterPastEvents
);
export const getSNPastReleases = createSelector(
  getSNReleases,
  filterPastEvents
);

const getHomepageRolledOutCountries = createSelector(
  (_, filteredVersion) => filteredVersion,
  (_1, _2, filteredRegion) => filteredRegion,
  getCountryCoverages,
  getCountryDHLRegion,
  (state) => homepage.getRolloutCountries(state.homepage),
  (
    filteredVersion,
    filteredRegion,
    coverages,
    countryDHLRegions,
    homepageRolloutCountries
  ) => {
    const getStatusForRollout = (releases, version) => {
      if (
        releases.filter((r) => r.date > CURRENT_DATE).length === releases.length
      ) {
        return HOMEPAGE_RELEASE_STATUS.PLANNED;
      }

      if (version === HOMEPAGE_VERSIONS.LIGHT) {
        return HOMEPAGE_RELEASE_STATUS.LIGHT_RELEASED;
      }

      return HOMEPAGE_RELEASE_STATUS.FULL_RELEASED;
    };

    return homepageRolloutCountries
      .filter(
        ({ country, version }) =>
          (filteredVersion === HOMEPAGE_VERSIONS.ALL ||
            filteredVersion === version) &&
          (!filteredRegion || countryDHLRegions[country] === filteredRegion)
      )
      .map(({ country, version, releases }) => ({
        country,
        coverage: coverages && coverages[country],
        releases,
        status: getStatusForRollout(releases, version),
        version,
      }));
  }
);

export const getFSAnalyticsData = (state) =>
  compose(
    RD.withDefault({}),
    RD.map(prop("leadNumbers"))
  )(customerJourneys.getFSAnalyticsData(state.customerJourneys));

export const getFSAnalyticsLastModified = (state) =>
  compose(
    RD.withDefault(undefined),
    RD.map(prop("lastModified"))
  )(customerJourneys.getFSAnalyticsData(state.customerJourneys));

export const getFSAnalyticsFilter = (state) =>
  customerJourneys.getFSAnalyticsFilter(state.customerJourneys);

export const getFSAnalyticsBusinessUnits = createSelector(
  getFSAnalyticsData,
  filterUniqueFilterBUs
);

export const getGAQAnalyticsData = (state) =>
  RD.withDefault(
    {},
    customerJourneys.getGAQAnalyticsData(state.customerJourneys)
  );

export const getGAQAnalyticsFilter = (state) =>
  customerJourneys.getGAQAnalyticsFilter(state.customerJourneys);

export const getGAQAnalyticsBusinessUnits = createSelector(
  getGAQAnalyticsData,
  filterUniqueFilterBUs
);

const getCJRolledOutCountries = createSelector(
  (_, application) => application,
  (_1, _2, filteredRegion) => filteredRegion,
  getCountryCoverages,
  getCountryDHLRegion,
  getFSReleases,
  getFSRolloutCountries,
  getGAQReleaseNotes,
  getGAQRollouts,
  (
    application,
    filteredRegion,
    coverages,
    countryDHLRegions,
    fsReleases,
    fsRolloutCountries,
    gaqReleases,
    gaqRolloutCountries
  ) => {
    const makeRolloutCountries = aggregateCountryReleases(
      filteredRegion,
      countryDHLRegions,
      coverages
    );

    switch (application) {
      case APPLICATIONS.GET_A_QUOTE:
        return Applications.getAQuote(
          makeRolloutCountries(gaqRolloutCountries, gaqReleases)
        );

      case APPLICATIONS.FREQUENT_SHIPMENT:
        return Applications.frequentShipment(
          makeRolloutCountries(fsRolloutCountries, fsReleases)
        );

      case APPLICATIONS.ALL: {
        const gaqCountries = makeRolloutCountries(
          gaqRolloutCountries,
          gaqReleases
        );

        const fsCountries = makeRolloutCountries(
          fsRolloutCountries,
          fsReleases
        );

        const uniqueCountries = [
          ...new Set([...gaqCountries, ...fsCountries].map(prop("country"))),
        ].sort();

        const data = uniqueCountries.map((country) => {
          const fsData = getRolloutsForCountry(country, fsCountries);
          const gaqData = getRolloutsForCountry(country, gaqCountries);

          return {
            country,
            coverage: coverages[country],
            status: getMostRelevantStatus(fsData.status, gaqData.status),
            applications: {
              frequentShipment: fsData,
              getAQuote: gaqData,
            },
          };
        });

        return Applications.all(data);
      }

      default:
        return [];
    }
  }
);

export const getFilteredHomepageRolledOutCountries = createSelector(
  getHomepageRolledOutCountries,
  getHomepageReleaseStatusFilter,
  getCountryRegions,
  getHomepageMapRegionFilter,
  (rolledOutCountries, releaseStatus, regions, selectedRegion) =>
    compose(
      filterRolloutByStatus(releaseStatus),
      filterRolloutByRegion(regions, selectedRegion)
    )(rolledOutCountries)
);

export const getMapRegionFilteredCJRolledOutCountries = createSelector(
  getCJRolledOutCountries,
  getCJReleaseStatusFilter,
  getCountryRegions,
  getCJMapRegionFilter,
  (rolledOutCountries, releaseStatus, regions, selectedRegion) =>
    Applications.map(
      compose(
        filterRolloutByStatus(releaseStatus),
        filterRolloutByRegion(regions, selectedRegion)
      ),
      rolledOutCountries
    )
);

export const getFilteredCJRolledOutCountries = createSelector(
  getCJRolledOutCountries,
  getCJReleaseStatusFilter,
  getCountryRegions,
  getCJMapRegionFilter,
  (rolledOutCountries, releaseStatus) =>
    Applications.map(filterRolloutByStatus(releaseStatus), rolledOutCountries)
);

export const getHomepageRolledOutAggregatedStatus = createSelector(
  getHomepageRolledOutCountries,
  getCountryRegions,
  getHomepageMapRegionFilter,
  (rolledOutCountries, regions, selectedRegion) =>
    compose(
      aggregateStatus,
      filterRolloutByRegion(regions, selectedRegion)
    )(rolledOutCountries)
);

export const getCJRolledOutAggregatedStatus = createSelector(
  getCJRolledOutCountries,
  getCountryRegions,
  getCJMapRegionFilter,
  (rolledOutCountries, regions, selectedRegion) =>
    compose(
      Applications.getValue,
      Applications.map(aggregateStatus),
      Applications.map(filterRolloutByRegion(regions, selectedRegion))
    )(rolledOutCountries)
);

export const getHomepageRegionsWithRolledOutCountries = createSelector(
  getHomepageRolledOutCountries,
  getCountryRegions,
  (rolledOutCountries, regions) =>
    compose(
      uniq,
      flatten,
      map((r) => regions[r.country])
    )(rolledOutCountries)
);

export const getCJRegionsWithRolledOutCountries = createSelector(
  getCJRolledOutCountries,
  getCountryRegions,
  (rolledOutCountries, regions) =>
    compose(
      uniq,
      flatten,
      Applications.getValue,
      Applications.map(map((r) => regions[r.country]))
    )(rolledOutCountries)
);

export const getHomepageMapData = createSelector(
  getFilteredHomepageRolledOutCountries,
  getHomepageMapDisplayModeFilter,
  getCountryCoordinates,
  (rolledOutCountries, displayMode, coordinates) =>
    makeMapData(rolledOutCountries, displayMode, coordinates)
);

export const getCJMapData = createSelector(
  getMapRegionFilteredCJRolledOutCountries,
  getCJMapDisplayModeFilter,
  getCountryCoordinates,
  (rolledOutCountries, displayMode, coordinates) =>
    makeMapData(
      Applications.getValue(rolledOutCountries),
      displayMode,
      coordinates
    )
);

const getSizeAxisForRegion = (region) => {
  const shared = {
    minValue: 0,
    maxValue: 10,
  };

  switch (region) {
    case MAP_REGIONS.WORLD:
      return {
        ...shared,
        maxSize: 15,
        minSize: 3,
      };

    case MAP_REGIONS.AMERICA_NORTH:
      return {
        ...shared,
        maxSize: 17,
        minSize: 3,
      };

    default:
      return {
        ...shared,
        maxSize: 25,
        minSize: 8,
      };
  }
};

const getMapOptions = (colors, colorValues) => (region, displayMode) => ({
  colorAxis: {
    colors,
    values: colorValues,
  },
  displayMode,
  geochartVersion: 11,
  legend: "none",
  region,
  sizeAxis: getSizeAxisForRegion(region),
  tooltip: { trigger: "none" },
});

export const getHomepageMapOptions = createSelector(
  getHomepageMapRegionFilter,
  getHomepageMapDisplayModeFilter,
  getMapOptions(
    [PLANNED_RELEASE_COLOR, PARTIALLY_RELEASED_COLOR, RELEASED_COLOR],
    [
      getCodeForStatus(HOMEPAGE_RELEASE_STATUS.PLANNED),
      getCodeForStatus(HOMEPAGE_RELEASE_STATUS.LIGHT_RELEASED),
      getCodeForStatus(HOMEPAGE_RELEASE_STATUS.FULL_RELEASED),
    ]
  )
);

export const getCJMapOptions = createSelector(
  getCJMapRegionFilter,
  getCJMapDisplayModeFilter,
  getMapOptions(
    [PLANNED_RELEASE_COLOR, PARTIALLY_RELEASED_COLOR, RELEASED_COLOR],
    [
      getCodeForStatus(RELEASE_STATUS.PLANNED),
      getCodeForStatus(RELEASE_STATUS.PARTIALLY_RELEASED),
      getCodeForStatus(RELEASE_STATUS.RELEASED),
    ]
  )
);

export const getProjectOverviewEntries = (state) =>
  compose(prop("entries"))(projectOverview.get(state.projectOverview));

export const getGAQReleaseNotesDecomposed = (state) =>
  customerJourneys.getGAQReleaseNotes(state.customerJourneys);

export const getDeleteReleaseNote = (state) =>
  customerJourneys.getDeleteReleaseNote(state.customerJourneys);

export const getReleaseNote = (state) =>
  RD.withDefault(
    {},
    customerJourneys.getGAQReleaseNote(state.customerJourneys)
  );

export default reducers;
