import { identity, keys } from "ramda";
import {
  PHASE_IV_COUNTRIES,
  PHASE_V_COUNTRIES,
  ROLLOUT_TRACKS,
} from "../../utils/constants";

const ENTRIES = [
  {
    // FS PHASE IV
    contact: "Martin Sallge",
    description:
      "Frequent Shipper (aka DHL for Business) enables users to get in contact with the correct BU sales team(s) depending on their shipment profiles.{br}{br}With the Phase IV rollout, we unlock an additional 34 xBU markets, ~4% global coverage.",
    detailLink: "fs-phase-iv-rollout",
    endDate: new Date(2022, 9, 31),
    fullName: "Frequent Shipper Phase IV Rollout",
    initials: "FS",
    markets: keys(PHASE_IV_COUNTRIES),
    languages: ["en", "ll"],
    link: "https://dci.dhl.com/rollout-progress/customer-journeys/frequent-shipment",
    shortName: "Phase IV Rollout",
    startDate: new Date(2022, 1, 1),
    track: ROLLOUT_TRACKS.CUSTOMER_JOURNEYS,
  },
  {
    // FS 2.0
    contact: "Martin Sallge",
    description:
      "Frequent Shipper (aka DHL for Business) enables users to get in contact with the correct BU sales team(s) depending on their shipment profiles.{br}{br}With the 2.0 upgrade, we are overhauling the frontend to guide users through the tool more elegantly and improve lead quality.",
    detailLink: "fs-20-rollout",
    endDate: new Date(2022, 5, 15),
    fullName: "Frequent Shipper 2.0 Upgrade",
    initials: "FS",
    markets: "66 markets",
    languages: ["en", "ll"],
    link: "https://dci.dhl.com/rollout-progress/customer-journeys/frequent-shipment",
    shortName: "FS 2.0 Upgrade",
    startDate: new Date(2021, 9, 1),
    track: ROLLOUT_TRACKS.CUSTOMER_JOURNEYS,
  },
  {
    // FS CONNECTORS APAC
    contact: "Martin Sallge",
    description:
      "Frequent Shipper (aka DHL for Business) enables users to get in contact with the correct BU sales team(s) depending on their shipment profiles.{br}{br}With Connectors, we improve the way users can get in contact with the correct sales team(s), e.g. by scheduling a callback.",
    detailLink: "fs-connectors-pilot-exp-apac",
    endDate: new Date(2021, 9, 31),
    fullName: "Frequent Shipper Connectors Pilot EXP APAC",
    initials: "FS",
    markets: ["SG", "NZ"],
    languages: ["en"],
    link: "https://www.dhl.com/sg-en/home/dhl-for-business.html",
    shortName: "Connectors Pilot APAC",
    startDate: new Date(2021, 6, 21),
    track: ROLLOUT_TRACKS.CUSTOMER_JOURNEYS,
  },
  {
    // FS CONNECTORS PILOT COUNTRIES
    contact: "Martin Sallge",
    description:
      "Frequent Shipper (aka DHL for Business) enables users to get in contact with the correct BU sales team(s) depending on their shipment profiles.{br}{br}With Connectors, we improve the way users can get in contact with the correct sales team(s), e.g. by scheduling a callback.",
    detailLink: "fs-connectors-pilot-exp-2022",
    endDate: new Date(2022, 3, 6),
    fullName: "Frequent Shipper Connectors Pilot EXP 2022",
    initials: "FS",
    markets: ["IE", "FR", "CH", "RO", "IT", "GB", "MY", "TH", "TW", "PH"],
    languages: ["en", "ll"],
    link: "https://www.dhl.com/gb-en/home/dhl-for-business.html",
    shortName: "Connectors Pilot 2022",
    startDate: new Date(2022, 2, 9),
    track: ROLLOUT_TRACKS.CUSTOMER_JOURNEYS,
  },
  {
    // FS CONNECTORS APAC, EU, MENA & AMERICAS COUNTRIES
    contact: "Martin Sallge",
    description:
      "Frequent Shipper (aka DHL for Business) enables users to get in contact with the correct BU sales team(s) depending on their shipment profiles.{br}{br}With Connectors, we improve the way users can get in contact with the correct sales team(s), e.g. by scheduling a callback.",
    detailLink: "fs-connectors-apac-eu-mena-americas-exp-2022",
    endDate: new Date(2022, 9, 31),
    fullName:
      "Frequent Shipper Connectors APAC, EMEA, MENA & Americas EXP 2022",
    initials: "FS",
    markets: [
      "IN",
      "KR",
      "VN",
      "AU",
      "PK",
      "HK",
      "BD",
      "HR",
      "ID",
      "LK",
      "NO",
      "AT",
      "ES",
      "GR",
      "LT",
      "SE",
      "DK",
      "FI",
      "PL",
      "PT",
      "TR",
      "US",
      "AE",
      "BG",
      "CZ",
      "HU",
      "IL",
      "SK",
      "EE",
      "JP",
      "KZ",
      "LV",
      "EG",
      "KW",
      "SA",
      "AR",
      "BR",
      "CA",
      "CO",
      "MX",
      "PE",
      "CL",
    ],
    languages: ["en", "ll"],
    link: "https://www.dhl.com/gb-en/home/dhl-for-business.html",
    shortName: "Connectors APAC, EU, MENA & Americas EXP 2022",
    startDate: new Date(2022, 4, 30),
    track: ROLLOUT_TRACKS.CUSTOMER_JOURNEYS,
  },
  {
    // GAQ PHASE IV
    contact: "Hartog de Mik",
    description:
      "The xBU Quoting Tool enables users to get in compare and contrast products and prices depending on their shipping requirements.{br}{br}With the Phase IV rollout, we unlock an additional 10 xBU markets, ~8% global coverage.",
    detailLink: "gaq-phase-4-rollout",
    endDate: new Date(2022, 0, 31),
    fullName: "GaQ Phase 4 Rollout",
    initials: "GAQ",
    markets: ["IE", "AE", "GH", "PE", "CL", "HK", "NG", "SA", "QA", "KE"],
    languages: ["en", "ll"],
    link: "https://www.dhl.com/sa-en/home/get-a-quote.html",
    shortName: "Phase 4 Rollout",
    startDate: new Date(2021, 9, 1),
    track: ROLLOUT_TRACKS.CUSTOMER_JOURNEYS,
  },
  {
    // GAQ PHASE V
    contact: "Hartog de Mik",
    description:
      "The xBU Quoting Tool enables users to get in compare and contrast products and prices depending on their shipping requirements.{br}{br}With the Phase V rollout, we unlock an additional 13 xBU markets, ~5% global coverage.",
    detailLink: "gaq-phase-5-rollout",
    endDate: new Date(2022, 3, 30),
    fullName: "GaQ Phase 5 Rollout",
    initials: "GAQ",
    markets: [
      "TR",
      "LB",
      "CR",
      "PA",
      "VN",
      "EG",
      "SV",
      "PK",
      "LK",
      "BD",
      "KW",
      "HN",
      "UG",
    ],
    languages: ["en", "ll"],
    link: "https://www.dhl.com/tr-en/home/get-a-quote.html",
    shortName: "Phase 5 Rollout",
    startDate: new Date(2022, 0, 1),
    track: ROLLOUT_TRACKS.CUSTOMER_JOURNEYS,
  },
  {
    // GAQ SIMPLE SITE
    contact: "Hartog de Mik",
    description:
      "The xBU Quoting Tool enables users to get in compare and contrast products and prices depending on their shipping requirements.{br}{br}With the Phase VI rollout, we unlock an additional 31 xBU markets, ~2% global coverage.",
    detailLink: "gaq-simple-site-rollout",
    endDate: new Date(2022, 4, 31),
    fullName: "GaQ Simple site GaQ rollout",
    initials: "GAQ",
    markets: [
      "AL",
      "BS",
      "BB",
      "BZ",
      "BM",
      "BT",
      "BO",
      "VG",
      "BN",
      "KY",
      "CW",
      "CY",
      "DO",
      "TL",
      "GE",
      "GP",
      "HT",
      "IS",
      "JM",
      "LA",
      "MO",
      "MV",
      "MT",
      "MQ",
      "MN",
      "NP",
      "PY",
      "RE",
      "VC",
      "TT",
      "UY",
    ],
    languages: ["en", "ll"],
    link: "https://www.dhl.com/al-en/home/get-a-quote.html",
    shortName: "Simple site GaQ rollout",
    startDate: new Date(2022, 3, 1),
    track: ROLLOUT_TRACKS.CUSTOMER_JOURNEYS,
  },
  {
    // SHIP NOW MVP
    contact: "Hartog de Mik",
    description:
      "Ship Now enables users to quickly & accurately off-board to BU touchpoints with minimal input required.{br}{br}With the MVP rollout, we unlock an additional 10 xBU markets, ~50% global coverage.",
    detailLink: "ship-now-10-biggest-markets",
    endDate: new Date(2022, 2, 31),
    fullName: "Ship Now 10 Biggest Markets",
    initials: "SN",
    markets: ["AU", "IT", "MX", "ZA", "NL", "FR", "KR", "CN", "GB", "US"],
    languages: ["en", "ll"],
    link: "https://www.dhl.com/au-en/home/book-online.html",
    shortName: "MVP Rollout",
    startDate: new Date(2022, 2, 1),
    track: ROLLOUT_TRACKS.CUSTOMER_JOURNEYS,
  },
  {
    // DHL.COM CONTENT 2.0 US
    contact: "Katherine Di Geronimo",
    description:
      "Content 2.0 initiative is about boosting DHL.com by simplifying site architecture, elevating content presentation and optimizing search engine results pages.",
    detailLink: "content-20-us-pilot",
    endDate: new Date(2022, 4, 31),
    fullName: "DHL.com - Content 2.0 US Pilot Go Live",
    initials: "Content 2.0",
    markets: ["US"],
    languages: ["en"],
    link: "https://www.dhl.com/us-en/home/our-divisions/dhl-supply-chain.html",
    shortName: "US Pilot Market",
    startDate: new Date(2021, 6, 1),
    track: ROLLOUT_TRACKS.DHL_COM,
  },
  {
    // DHL.COM CONTENT 2.0 +5 PILOT MARKETS
    contact: "Katherine Di Geronimo",
    description:
      "Content 2.0 initiative is about boosting DHL.com by simplifying site architecture, elevating content presentation and optimizing search engine results pages.",
    detailLink: "content-20-5-pilots",
    endDate: new Date(2022, 7, 31),
    fullName: "DHL.com - Content 2.0 +5 Pilots Go Live",
    initials: "Content 2.0",
    markets: ["IT", "ES", "MX", "JM", "JO"],
    languages: ["en", "ll"],
    link: "https://www.dhl.com/it-en/home/our-divisions/dhl-supply-chain.html",
    shortName: "+ 5 Pilot Markets",
    startDate: new Date(2021, 9, 1),
    track: ROLLOUT_TRACKS.DHL_COM,
  },
  {
    // DHL.COM CONTENT 2.0 REST OF WORLD
    contact: "Katherine Di Geronimo",
    description:
      "Content 2.0 initiative is about boosting DHL.com by simplifying site architecture, elevating content presentation and optimizing search engine results pages.",
    detailLink: "content-20-rest-of-world",
    endDate: new Date(2024, 0, 1),
    fullName: "DHL.com - Content 2.0 Rest of World Rollout",
    initials: "Content 2.0",
    markets: "Rest of World. Country waves TBD.",
    languages: ["en", "ll"],
    shortName: "Rest of World rollout ",
    startDate: new Date(2022, 9, 1),
    track: ROLLOUT_TRACKS.DHL_COM,
  },
  {
    // FS-X Pilot
    contact: "Celine C. Zacharias",
    description:
      "FS-X (the express-specific version of the Frequent Shipper tool) boosts digital leads for Express by providing a unique customer journey for business customers; first on the Express subsite on DHL.com and then on Express-specific pages such as DHL Discover, MyDHL+ and more.",
    detailLink: "fsx-pilot-launch",
    endDate: new Date(2023, 4, 30),
    fullName:
      "Pilot Launch of FS-X in US, IT, MY on the EXP subsite on DHL.com",
    initials: "FSX",
    markets: ["US", "IT", "MY"],
    languages: ["en", "it"],
    link: "https://www.dhl.com/us-en/home/express.html",
    shortName: "FS-X Pilot",
    startDate: new Date(2023, 1, 1),
    track: ROLLOUT_TRACKS.CUSTOMER_JOURNEYS,
  },
  {
    // QUICK ECS
    contact: "Celine C. Zacharias",
    description:
      "To better preselect only leads relevant for eCS, a spinoff version of the xBU tool of Frequent Shipper was developed and implemented in the US.",
    detailLink: "quick-ecs-launch",
    endDate: new Date(2023, 4, 11),
    fullName: "Launch of Quick eCS in the US",
    initials: "eCS",
    markets: ["US"],
    languages: ["en"],
    link: "https://www.dhl.com/us-en/home/ecommerce-solutions/request-a-quote.html",
    shortName: "Quick eCS",
    startDate: new Date(2023, 3, 1),
    track: ROLLOUT_TRACKS.CUSTOMER_JOURNEYS,
  },
  {
    // SCHEDULE A CALLBACK PHASE 2
    contact: "Celine C. Zacharias",
    description:
      "To increase lead conversion rates and decrease the number of users qualifying out due to not being reachable, users can now select an exact time slot at which they wish to be called back and will receive a respective notification.",
    detailLink: "schedule-a-callback-phase-2",
    endDate: new Date(2023, 7, 31),
    fullName:
      "Launch of Feature improvement to the 'Schedule-a-Callback' Connector",
    initials: "SACB P2",
    shortName: "Schedule-a-Callback Phase 2",
    startDate: new Date(2023, 7, 1),
    track: ROLLOUT_TRACKS.CUSTOMER_JOURNEYS,
  },
  {
    // FS CONNECTORS BE & AFRICAN COUNTRIES
    contact: "Celine C. Zacharias",
    description:
      "Frequent Shipper (aka DHL for Business) enables users to get in contact with the correct BU sales team(s) depending on their shipment profiles.{br}{br}With Connectors, we improve the way users can get in contact with the correct sales team(s), e.g. by scheduling a callback.",
    detailLink: "fs-connectors-african-countries-be-exp-2023",
    endDate: new Date(2023, 4, 30),
    fullName:
      "Frequent Shipper Connectors African Countries & Belgium EXP 2023",
    initials: "FS",
    markets: ["GH", "KE", "NG", "ZA", "BE"],
    languages: ["en", "ll"],
    link: "https://www.dhl.com/gb-en/home/dhl-for-business.html",
    shortName: "Connectors African Countries & BE EXP 2023",
    startDate: new Date(2023, 1, 6),
    track: ROLLOUT_TRACKS.CUSTOMER_JOURNEYS,
  },
  {
    // FS PHASE V
    contact: "Celine C. Zacharias",
    description:
      "Frequent Shipper (aka DHL for Business) enables users to get in contact with the correct BU sales team(s) depending on their shipment profiles.{br}{br}With the Phase V rollout, we unlock an additional 31 xBU and EXP-only markets.",
    detailLink: "fs-phase-v-rollout",
    endDate: new Date(2024, 7, 13),
    fullName: "Frequent Shipper Phase V Rollout",
    initials: "FS",
    markets: keys(PHASE_V_COUNTRIES),
    languages: ["en", "ll"],
    link: "https://dci.dhl.com/rollout-progress/customer-journeys/frequent-shipment",
    shortName: "Phase V Rollout",
    startDate: new Date(2023, 10, 15),
    track: ROLLOUT_TRACKS.CUSTOMER_JOURNEYS,
  },
];

const INITIAL_STATE = {
  entries: ENTRIES,
};

const reducer = (state = INITIAL_STATE) => {
  return state;
};

export default reducer;

export const get = identity;
