import { memo } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../reducers/actions";
import Urgency from ".";

const mapDispatchToProps = {
  setBusinessUnitUrgency: actions.setBusinessUnitUrgency,
};

export default memo(connect(null, mapDispatchToProps)(Urgency));
