import Copy from "../assets/copy/index.json";
import {
  NOT_PLANNED_COLOR,
  PARTIALLY_RELEASED_COLOR,
  PLANNED_RELEASE_COLOR,
  RELEASED_COLOR,
} from "../styles";
import {
  HOMEPAGE_RELEASE_STATUS,
  RELEASE_STATUS,
  SORT_DIRECTIONS,
} from "./constants";

export const normalizeURL = (url) =>
  url.replace(/(https?:\/\/)|(\/)+/g, "$1$2");

export const prependWithBaseURL = (pathname) =>
  normalizeURL(
    `${window.location.protocol}//${window.location.host}/${pathname}`
  );

export const sortByCountry =
  (direction = SORT_DIRECTIONS.ASC) =>
  (a, b) =>
    direction === SORT_DIRECTIONS.ASC
      ? (Copy[`Country.${a.country || a}`] || "").localeCompare(
          Copy[`Country.${b.country || b}`]
        )
      : (Copy[`Country.${b.country || b}`] || "").localeCompare(
          Copy[`Country.${a.country || a}`]
        );

export const getColorForStatus = (status) => {
  switch (status) {
    case HOMEPAGE_RELEASE_STATUS.FULL_RELEASED:
    case RELEASE_STATUS.ALL_APPS_RELEASED:
    case RELEASE_STATUS.RELEASED:
      return RELEASED_COLOR;

    case HOMEPAGE_RELEASE_STATUS.LIGHT_RELEASED:
    case RELEASE_STATUS.ONE_APP_RELEASED:
    case RELEASE_STATUS.PARTIALLY_RELEASED:
      return PARTIALLY_RELEASED_COLOR;

    case HOMEPAGE_RELEASE_STATUS.PLANNED:
    case RELEASE_STATUS.ONE_APP_PLANNED:
    case RELEASE_STATUS.PLANNED:
      return PLANNED_RELEASE_COLOR;

    case RELEASE_STATUS.NOT_PLANNED:
      return NOT_PLANNED_COLOR;

    default:
      return null;
  }
};
