import { memo } from "react";
import { connect } from "react-redux";
import * as actions from "../../reducers/actions";
import * as reducers from "../../reducers";
import Modals from ".";

const mapStateToProps = (state) => ({
  data: reducers.getModalData(state),
  openedModal: reducers.getOpenedModal(state),
});

const mapDispatchToProps = {
  closeModal: actions.closeModal,
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(Modals));
