import React, { memo, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import { Headline, THEME, Button, Text } from "@dhl-official/react-ui-library";
import RD from "../../../utils/functional/remote-data";

// #region Component Styles
const APPEAR_FROM_TOP = keyframes`
  from {
    transform: translateY(-10px) scale(0.9);
    opacity: 0;
  }

  to {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
`;

const Container = styled.div`
  min-width: 350px;
`;

const Title = styled(Headline).attrs({
  designLevel: 3,
  tag: Headline.H3,
})`
  color: ${({ shouldHighlight }) =>
    shouldHighlight
      ? THEME.getPrimaryColor("400")
      : THEME.getFontColor("regular")};

  animation: ${THEME.getAnimationSpeed("regular")} ease-in-out 0s forwards
    ${APPEAR_FROM_TOP};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Release = styled(Text).attrs({
  isParagraph: true,
})`
  text-decoration: ${({ $isDeleted }) =>
    $isDeleted ? "line-through" : undefined};
`;

const FailureNotice = styled(Text).attrs({
  size: Text.SMALL,
  isParagraph: true,
})`
  animation: ${APPEAR_FROM_TOP} ${THEME.getAnimationSpeed("regular")};
  color: ${THEME.getPrimaryColor("400")};
`;

const SuccessNotice = styled(FailureNotice)`
  color: ${THEME.getSuccessColor("300")};
  margin-top: 0;
`;

const DeleteButton = styled(Button).attrs({
  variant: Button.PRIMARY,
})`
  margin-top: ${THEME.getUnit("large")};
`;

const CancelButton = styled(Button).attrs({
  variant: Button.TEXT,
})`
  margin-top: 0;
`;
// #endregion

const DeleteReleaseNoteModal = ({
  closeModal,
  deleteReleaseNote,
  deleteReleaseNoteStatus,
  data,
  resetReleaseNoteStatus,
}) => {
  const { id, formattedDate } = data;
  useEffect(resetReleaseNoteStatus, []);

  return (
    <Container>
      <Title>
        {RD.isSuccess(deleteReleaseNoteStatus)
          ? "Entry deleted"
          : "Delete this entry?"}
      </Title>
      <Content>
        <Release $isDeleted={RD.isSuccess(deleteReleaseNoteStatus)}>
          Release Note from {formattedDate}
        </Release>
        {RD.isSuccess(deleteReleaseNoteStatus) && (
          <SuccessNotice>Entry was successfully deleted!</SuccessNotice>
        )}
        {RD.isError(deleteReleaseNoteStatus) && (
          <FailureNotice>
            There has been an error while trying to delete this entry!
          </FailureNotice>
        )}
        {!RD.isSuccess(deleteReleaseNoteStatus) && (
          <DeleteButton onClick={() => deleteReleaseNote(id)}>
            Delete
          </DeleteButton>
        )}
        <CancelButton onClick={closeModal}>
          {RD.isSuccess(deleteReleaseNoteStatus) ? "Close" : "Cancel"}
        </CancelButton>
      </Content>
    </Container>
  );
};

DeleteReleaseNoteModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  deleteReleaseNote: PropTypes.func.isRequired,
  deleteReleaseNoteStatus: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  resetReleaseNoteStatus: PropTypes.func.isRequired,
};

export default memo(DeleteReleaseNoteModal);
