import { combineReducers } from "redux";
import rolloutCountriesReducer, * as rolloutCountries from "./rollout-countries";
import releasesReducer, * as releases from "./releases";

export default combineReducers({
  releases: releasesReducer,
  rolloutCountries: rolloutCountriesReducer,
});

export const getReleases = (state) => releases.get(state.releases);

export const getRolloutCountries = (state) =>
  rolloutCountries.get(state.rolloutCountries);
