/* eslint-disable no-underscore-dangle */

import { curry, prop, propEq } from "ramda";

const COUNTRY_SETTINGS_TYPES = {
  FRESH: "Fresh",
  EXISTING: "Existing",
};

const NOOP = Function.prototype;

const CountrySettings = {
  fresh: (data) => ({
    _data: data,
    tag: COUNTRY_SETTINGS_TYPES.FRESH,
  }),

  existing: (data) => ({
    _data: data,
    tag: COUNTRY_SETTINGS_TYPES.EXISTING,
  }),

  case: curry((caseObj, countrySettings) => {
    const { fresh, existing, _ = NOOP } = caseObj;
    const { _data } = countrySettings;

    if (fresh && CountrySettings.isFresh(countrySettings)) {
      return fresh(_data);
    }

    if (existing && CountrySettings.isExisting(countrySettings)) {
      return existing(_data);
    }

    return _(_data);
  }),

  isFresh: propEq("tag", COUNTRY_SETTINGS_TYPES.FRESH),

  isExisting: propEq("tag", COUNTRY_SETTINGS_TYPES.EXISTING),

  getValue: prop("_data"),
};

export default CountrySettings;
