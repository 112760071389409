import { combineReducers } from "redux";
import dataReducer, * as data from "./data";
import filterReducer, * as filter from "./filter";

export default combineReducers({
  data: dataReducer,
  filter: filterReducer,
});

export const getData = (state) => data.get(state.data);

export const getFilter = (state) => filter.get(state.filter);
