import { compose } from "ramda";
import {
  GET_GAQ_ANALYTICS,
  GET_GAQ_ANALYTICS_FAILURE,
  GET_GAQ_ANALYTICS_SUCCESS,
} from "../../../../../actions";
import * as reducers from "../../../..";
import RD from "../../../../../utils/functional/remote-data";

const fetchGAQAnalytics = () => ({
  type: GET_GAQ_ANALYTICS,
});

const fetchGAQAnalyticsSuccess = (payload) => ({
  type: GET_GAQ_ANALYTICS_SUCCESS,
  payload,
});

const fetchGAQAnalyticsFailure = (payload) => ({
  type: GET_GAQ_ANALYTICS_FAILURE,
  error: true,
  payload,
});

export const fetchGAQAnalyticsEffect =
  () =>
  (dispatch, getState, { api }) => {
    const accessToken = RD.withDefault("", reducers.getLoggedUser(getState()));

    dispatch(fetchGAQAnalytics());

    api
      .fetchGAQLeadNumbers(accessToken)
      .then(compose(dispatch, fetchGAQAnalyticsSuccess))
      .catch(compose(dispatch, fetchGAQAnalyticsFailure));
  };
