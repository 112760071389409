import { memo } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../reducers/actions";
import Threshold from ".";

const mapDispatchToProps = {
  setBusinessUnitThresholdEnabled: actions.setBusinessUnitThresholdEnabled,
  setBusinessUnitThreshold: actions.setBusinessUnitThreshold,
};

export default memo(connect(null, mapDispatchToProps)(Threshold));
