import { identity } from "ramda";
import { RELEASE_TYPES } from "../../../../utils/constants";

const INITIAL_STATE = [
  {
    countries: [
      { country: "GB", languages: ["en"] },
      { country: "MX", languages: ["en", "es"] },
      { country: "IN", languages: ["en"] },
    ],
    date: new Date(2019, 10, 27),
    title: "Rollouts in MVP Countries Completed",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-27.11.2019",
  },

  {
    countries: [
      { country: "FR", languages: ["en"] },
      { country: "ES", languages: ["en"] },
      { country: "PT", languages: ["en"] },
    ],
    date: new Date(2020, 1, 19),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-19.02.2020",
  },

  {
    countries: [
      { country: "CN", languages: ["en"] },
      { country: "CO", languages: ["en"] },
      { country: "IT", languages: ["en"] },
    ],
    date: new Date(2020, 1, 26),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-26.02.2020",
  },

  {
    countries: [
      { country: "CA", languages: ["en"] },
      { country: "JP", languages: ["en"] },
      { country: "KR", languages: ["en"] },
    ],
    date: new Date(2020, 2, 4),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-04.03.2020",
  },

  {
    countries: [
      { country: "PL", languages: ["en"] },
      { country: "BR", languages: ["en"] },
      { country: "AT", languages: ["en"] },
    ],
    date: new Date(2020, 2, 11),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-11.03.2020",
  },

  {
    countries: [
      { country: "ZA", languages: ["en"] },
      { country: "US", languages: ["en"] },
      { country: "MY", languages: ["en"] },
      { country: "AU", languages: ["en"] },
    ],
    date: new Date(2020, 2, 18),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-18.03.2020",
  },

  {
    countries: [
      { country: "FR", languages: ["fr"] },
      { country: "ES", languages: ["es"] },
      { country: "PT", languages: ["pt"] },
    ],
    date: new Date(2020, 3, 8),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-08.04.2020",
  },

  {
    countries: [
      { country: "CN", languages: ["zh"] },
      { country: "CO", languages: ["es"] },
      { country: "IT", languages: ["it"] },
    ],
    date: new Date(2020, 3, 15),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-15.04.2020",
  },

  {
    countries: [
      { country: "CA", languages: ["fr"] },
      { country: "JP", languages: ["ja"] },
      { country: "KR", languages: ["ko"] },
    ],
    date: new Date(2020, 3, 22),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-22.04.2020",
  },

  {
    countries: [
      { country: "PL", languages: ["pl"] },
      { country: "BR", languages: ["pt"] },
      { country: "AT", languages: ["de"] },
    ],
    date: new Date(2020, 3, 29),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-29.04.2020",
  },

  {
    date: new Date(2020, 6, 15),
    features: [
      "Fixed a IE11 layout glitch",
      "We now show an error message if user has unstable connection",
      'Added support for optional note in the "Comments" field of step 3',
    ],
    isDetailed: false,
    summary:
      "Some organizations, such as Parcel Netherlands or P&P in Germany already have a web frontend in place to guide the user through a self-onboarding process. We now support these sales processes by off-ramping users that match up with one of those Business Units based on their shipment profile to those web frontends. Depending on the BUs the user has matched up with, they are either presented with a link right after filling out the shipment profile, or after submitting the contact form.",
    title: "Support for Self-Onboarding",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "19.0.0",
  },

  {
    date: new Date(2020, 7, 5),
    features: [
      'Improved layout of the "Thank you" Modal for small screen sizes',
      "Fixed layout issue with validation messages in the Contact Form",
    ],
    isDetailed: false,
    summary:
      "In order to more accurately distribute leads between either DHL Express or DHL eCS and DHL Parcel, we added two checkboxes in the shipment profile. The user is asked about their preference for either Express Shipments or Standard Shipments (or both), but if they don't make a choice here, we distribute the lead to both applicable Business Units, as we would've done before. {br}{br}This feature can be toggled on or off per country.",
    title: "Shipping Profile Now Asks for Shipping Urgency",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "20.1.0",
  },

  {
    countries: [
      { country: "NO", languages: ["en"] },
      { country: "CZ", languages: ["en"] },
      { country: "TW", languages: ["en"] },
    ],
    date: new Date(2020, 7, 12),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-12.08.2020",
  },

  {
    countries: [
      { country: "SA", languages: ["en"] },
      { country: "TR", languages: ["en"] },
      { country: "PE", languages: ["en"] },
    ],
    date: new Date(2020, 7, 19),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-19.08.2020",
  },

  {
    countries: [
      { country: "FI", languages: ["en"] },
      { country: "SE", languages: ["en"] },
      { country: "TH", languages: ["en"] },
    ],
    date: new Date(2020, 7, 26),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-26.08.2020",
  },

  {
    date: new Date(2020, 8, 2),
    features: [
      "We now allow for the postal code field to be optional depending on country configuration",
      "Enhanced the layout of the form buttons (Next and Previous) on mobile view",
    ],
    isDetailed: false,
    summary:
      "How much of the complexity that comes with DPDHL's Family of Divisions do we need to explain to the user? Or is that just an unnecessary mental burden? With more insights and user feedback, we've learned that we have to explain why the user might be contacted by multiple sales teams for their shipment needs anyway, And that makes us want to explain the concept of our Business Units sooner rather then later. A first step is that we now show the divisions that match the users shipment profile above the contact form so they know who they are giving their contact info to.{br}{br}This feature can be toggled on or off per country.",
    title: "Explaining the Family of Divisions",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "20.3.0",
  },

  {
    countries: [
      { country: "DK", languages: ["en"] },
      { country: "RO", languages: ["en"] },
    ],
    date: new Date(2020, 8, 2),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-02.09.2020",
  },

  {
    date: new Date(2020, 8, 7),
    features: [
      "Added a background validation for the phone number input field",
      "Allow for different Self-Onboarding links to be configured for different Business Units",
    ],
    isDetailed: false,
    title: "Improved the Modularity of Our Configuration",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "20.3.1",
  },

  {
    countries: [
      { country: "DE", languages: ["en"] },
      { country: "NL", languages: ["en"] },
      { country: "AR", languages: ["en"] },
    ],
    date: new Date(2020, 8, 9),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-09.09.2020",
  },

  {
    date: new Date(2020, 8, 16),
    features: [
      'Phone number validation error message ("hard validation") can now be configured per country',
    ],
    isDetailed: false,
    title: "Toggled Phone Number Validation ON for Germany",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "20.3.2",
  },

  {
    countries: [
      { country: "NZ", languages: ["en"] },
      { country: "SG", languages: ["en"] },
      { country: "PK", languages: ["en"] },
    ],
    date: new Date(2020, 8, 16),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-16.09.2020",
  },

  {
    date: new Date(2020, 8, 23),
    features: ["Mandatory form fields are now marked with an Asterisk (*)"],
    isDetailed: false,
    title: "Following Common UX Patterns",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "20.3.3",
  },

  {
    countries: [
      { country: "GR", languages: ["en"] },
      { country: "CL", languages: ["en"] },
    ],
    date: new Date(2020, 8, 23),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-23.09.2020",
  },

  {
    countries: [{ country: "IE", languages: ["en"] }],
    date: new Date(2020, 8, 30),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-30.09.2020",
  },

  {
    countries: [
      { country: "NO", languages: ["no"] },
      { country: "CZ", languages: ["cs"] },
      { country: "TW", languages: ["zh-TW"] },
    ],
    date: new Date(2020, 9, 7),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-07.10.2020",
  },

  {
    date: new Date(2020, 9, 14),
    features: [
      "Streamlined UI elements to be more in-line with the rest of DHL.com in terms of proportions and micro-animations",
      'Fixed a bug where the optional note in the "Comments" field was not displayed in rare instances',
    ],
    isDetailed: false,
    title: "Cut From the Same Cloth",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "20.3.4",
  },

  {
    countries: [
      { country: "TR", languages: ["tr"] },
      { country: "PE", languages: ["es"] },
    ],
    date: new Date(2020, 9, 14),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-14-10-2020",
  },

  {
    countries: [
      { country: "FI", languages: ["fi"] },
      { country: "SE", languages: ["sv"] },
      { country: "TH", languages: ["th"] },
    ],
    date: new Date(2020, 9, 21),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-21.10.2020",
  },

  {
    countries: [
      { country: "DK", languages: ["da"] },
      { country: "RO", languages: ["ro"] },
    ],
    date: new Date(2020, 9, 28),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-28.10.2020",
  },

  {
    date: new Date(2020, 9, 29),
    features: [
      "Further improved layout for cards, validation messages and progress indicator",
      "Added support for DPDHL Delivery 2.0 font and optimized loading of applicable font cuts depending on locale, learn more at <typographyUrl>https://www.dpdhl-brands.com/dhl/en/guides/design-basics/typography/typography.html</typographyUrl>",
    ],
    isDetailed: false,
    title: "Tailor-made Typeface",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "20.4.0",
  },

  {
    countries: [
      { country: "DE", languages: ["de"] },
      { country: "NL", languages: ["nl"] },
      { country: "AR", languages: ["es"] },
    ],
    date: new Date(2020, 10, 4),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-4.11.2020",
  },

  {
    date: new Date(2020, 10, 11),
    features: [
      "Improved user guidance for form fields by activating the Proceed button once all required input fields have been filled",
    ],
    isDetailed: false,
    summary:
      "Frequent Shipment has achieved 100% integration with the DHL Pattern Library. That means, our designs are automatically in-line with style guide, and will be in the future, any time the Pattern Library will get an update.{br}{br} In the process we were able to remove more the 3000 lines of custom code, which will also positively impact loading times, and we already contributed over 30 items back to the Pattern Library to be used by other teams at DHL.",
    title: "Proudly Powered by DHL Pattern Library",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "20.4.1",
  },

  {
    countries: [
      { country: "GR", languages: ["el"] },
      { country: "CL", languages: ["es"] },
    ],
    date: new Date(2020, 10, 18),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-18.11.2020",
  },

  {
    countries: [
      { country: "BE", languages: ["en"] },
      { country: "CH", languages: ["en"] },
      { country: "HK", languages: ["en"] },
    ],
    date: new Date(2020, 10, 25),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-25.11.2020",
  },

  {
    countries: [{ country: "HK", languages: ["zh"] }],
    date: new Date(2021, 1, 3),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-3.2.2021",
  },

  {
    date: new Date(2021, 0, 20),
    features: [
      "Taking stakeholder feedback into account we refined the lead distribution logic for select countries, e.g. by setting volume thresholds for certain business units or reducing the number of duplicate business unit responsibilities for certain shipping profiles",
    ],
    isDetailed: false,
    summary:
      "After rebuilding the application from the ground up during the code freeze period late 2020, we managed to improve loading times of the frontend substantially. Especially on slow connections it is noticable that the application loads in less than half the time.",
    title: "Tuning and Tweaking",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "21.0.0",
  },

  {
    countries: [{ country: "BE", languages: ["fr", "nl"] }],
    date: new Date(2021, 1, 17),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-17.2.2021",
  },

  {
    countries: [{ country: "CH", languages: ["de", "fr", "it"] }],
    date: new Date(2021, 2, 3),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-3.3.2021",
  },

  {
    countries: [
      { country: "LT", languages: ["en"] },
      { country: "HR", languages: ["en"] },
      { country: "EE", languages: ["en"] },
    ],
    date: new Date(2021, 3, 8),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-7.4.2021",
  },

  {
    date: new Date(2021, 3, 14),
    features: [
      "In order to guide users better in entering a valid phone number, we refined the UI element, added a combo box for selection of the country prefix (which is by default populated with the prefix of the selected country) and provided clearer error messages",
    ],
    isDetailed: false,
    title: "Phone Number Input Revisited",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "21.0.5",
  },

  {
    countries: [
      { country: "UA", languages: ["en"] },
      { country: "BG", languages: ["en"] },
    ],
    date: new Date(2021, 3, 14),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-14.4.2021",
  },

  {
    countries: [{ country: "HU", languages: ["en"] }],
    date: new Date(2021, 3, 22),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-22.4.2021",
  },

  {
    countries: [{ country: "LV", languages: ["en"] }],
    date: new Date(2021, 3, 21),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-21.4.2021",
  },

  {
    countries: [
      { country: "MA", languages: ["en"] },
      { country: "VE", languages: ["en"] },
      { country: "EC", languages: ["en"] },
    ],
    date: new Date(2021, 3, 28),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-28.4.2021",
  },

  {
    countries: [
      { country: "BH", languages: ["en"] },
      { country: "IL", languages: ["en"] },
      { country: "KE", languages: ["en"] },
      { country: "KW", languages: ["en"] },
      { country: "BO", languages: ["en"] },
    ],
    date: new Date(2021, 4, 5),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-5.5.2021",
  },

  {
    countries: [{ country: "AE", languages: ["en"] }],
    date: new Date(2021, 4, 12),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-12.5.2021",
  },

  {
    countries: [
      { country: "KZ", languages: ["en"] },
      { country: "SK", languages: ["en"] },
    ],
    date: new Date(2021, 4, 18),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-18.5.2021",
  },

  {
    countries: [{ country: "MA", languages: ["fr"] }],
    date: new Date(2021, 4, 19),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-19.5.2021",
  },

  {
    countries: [
      { country: "GH", languages: ["en"] },
      { country: "NG", languages: ["en"] },
    ],
    date: new Date(2021, 4, 26),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-26.5.2021",
  },

  {
    countries: [
      { country: "EC", languages: ["es"] },
      { country: "HR", languages: ["hr"] },
      { country: "LT", languages: ["lt"] },
    ],
    date: new Date(2021, 4, 27),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-27.5.2021",
  },

  {
    countries: [{ country: "PH", languages: ["en"] }],
    date: new Date(2021, 4, 28),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-28.5.2021",
  },

  {
    countries: [{ country: "SK", languages: ["sk"] }],
    date: new Date(2021, 5, 2),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-2.6.2021",
  },

  {
    countries: [
      { country: "BG", languages: ["bg"] },
      { country: "EE", languages: ["et"] },
    ],
    date: new Date(2021, 5, 9),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-9.6.2021",
  },

  {
    countries: [
      { country: "KZ", languages: ["ru"] },
      { country: "HU", languages: ["hu"] },
    ],
    date: new Date(2021, 5, 15),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-15.06.2021",
  },

  {
    countries: [{ country: "EG", languages: ["en"] }],
    date: new Date(2021, 5, 17),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-17.06.2021",
  },

  {
    countries: [{ country: "QA", languages: ["en"] }],
    date: new Date(2021, 4, 31),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-31.5.2021",
  },

  {
    countries: [
      { country: "VE", languages: ["es"] },
      { country: "UA", languages: ["uk", "ru"] },
      { country: "LV", languages: ["lv"] },
      { country: "BO", languages: ["es"] },
    ],
    date: new Date(2021, 4, 2),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-2.5.2021",
  },

  {
    countries: [{ country: "VN", languages: ["en"] }],
    date: new Date(2021, 6, 20),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-20.7.2021",
  },

  {
    countries: [{ country: "VN", languages: ["vi"] }],
    date: new Date(2021, 6, 29),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "countries-29.7.2021",
  },

  {
    date: new Date(2021, 8, 8),
    isDetailed: false,
    title: "🅰️🔀🅱️ FS 2.0 A/B Test starting in GB, AU and IL!",
    summary:
      "In preparation of the upcoming country rollout phase of FS 2.0, start of the A/B test phase in the markets of Great Britain, Israel and Australia.",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-v4.0.23",
  },

  {
    date: new Date(2021, 10, 9),
    isDetailed: false,
    title: "🏎 FS 2.0 Official Rollout Starting!",
    summary:
      "After months of hard work, we officially start rolling out our new application version called FS 2.0 to the first countries.",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-v4.2.8",
  },

  {
    countries: [
      { country: "IE", languages: ["en"] },
      { country: "GB", languages: ["en"] },
      { country: "AU", languages: ["en"] },
      { country: "IL", languages: ["en"] },
    ],
    date: new Date(2021, 10, 10),
    type: RELEASE_TYPES.V2_ROLLOUT,
    version: "fs2-10.11.2021",
  },

  {
    countries: [
      { country: "FI", languages: ["en", "fi"] },
      { country: "FR", languages: ["en", "fr"] },
      { country: "SA", languages: ["en"] },
      { country: "SK", languages: ["en", "sk"] },
    ],
    date: new Date(2021, 10, 17),
    type: RELEASE_TYPES.V2_ROLLOUT,
    version: "fs2-17.11.2021",
  },

  {
    countries: [
      { country: "BR", languages: ["en", "pt"] },
      { country: "CA", languages: ["en", "fr"] },
      { country: "DK", languages: ["en", "da"] },
      { country: "TW", languages: ["en", "zh-TW"] },
    ],
    date: new Date(2021, 10, 24),
    type: RELEASE_TYPES.V2_ROLLOUT,
    version: "fs2-24.11.2021",
  },

  {
    countries: [
      { country: "JP", languages: ["en", "ja"] },
      { country: "EG", languages: ["en"] },
      { country: "NO", languages: ["en", "no"] },
      { country: "GR", languages: ["en", "el"] },
      { country: "UA", languages: ["en", "uk"] },
    ],
    date: new Date(2021, 11, 1),
    type: RELEASE_TYPES.V2_ROLLOUT,
    version: "fs2-01.12.2021",
  },

  {
    countries: [
      { country: "ES", languages: ["en", "es"] },
      { country: "PT", languages: ["en", "pt"] },
      { country: "ZA", languages: ["en"] },
      { country: "LT", languages: ["en", "lt"] },
      { country: "CO", languages: ["en", "es"] },
      { country: "CL", languages: ["en", "es"] },
    ],
    date: new Date(2021, 11, 8),
    type: RELEASE_TYPES.V2_ROLLOUT,
    version: "fs2-08.12.2021",
  },

  {
    countries: [
      { country: "HR", languages: ["en", "hr"] },
      { country: "IT", languages: ["en", "it"] },
      { country: "MA", languages: ["en", "fr"] },
      { country: "RO", languages: ["en", "ro"] },
      { country: "PH", languages: ["en"] },
      { country: "VE", languages: ["en", "es"] },
    ],
    date: new Date(2021, 11, 15),
    type: RELEASE_TYPES.V2_ROLLOUT,
    version: "fs2-15.12.2021",
  },

  {
    countries: [
      { country: "PE", languages: ["en", "es"] },
      { country: "AR", languages: ["en", "es"] },
      { country: "TR", languages: ["en", "tr"] },
      { country: "AE", languages: ["en"] },
      { country: "QA", languages: ["en"] },
      { country: "NG", languages: ["en"] },
      { country: "KE", languages: ["en"] },
      { country: "BH", languages: ["en"] },
      { country: "IN", languages: ["en"] },
      { country: "MY", languages: ["en"] },
      { country: "GH", languages: ["en"] },
      { country: "US", languages: ["en"] },
    ],
    date: new Date(2021, 11, 22),
    type: RELEASE_TYPES.V2_ROLLOUT,
    version: "fs2-22.12.2021",
  },

  {
    countries: [
      { country: "KW", languages: ["en"] },
      { country: "EE", languages: ["en", "et"] },
      { country: "PK", languages: ["en"] },
    ],
    date: new Date(2021, 11, 23),
    type: RELEASE_TYPES.V2_ROLLOUT,
    version: "fs2-23.12.2021",
  },

  {
    countries: [
      { country: "BO", languages: ["en", "es"] },
      { country: "CZ", languages: ["en", "cs"] },
      { country: "DE", languages: ["en", "de"] },
      { country: "TH", languages: ["en", "th"] },
      { country: "VN", languages: ["en", "vi"] },
    ],
    date: new Date(2022, 0, 5),
    type: RELEASE_TYPES.V2_ROLLOUT,
    version: "fs2-06.01.2022",
  },

  {
    countries: [{ country: "HU", languages: ["en", "hu"] }],
    date: new Date(2022, 0, 12),
    type: RELEASE_TYPES.V2_ROLLOUT,
    version: "fs2-12.01.2022",
  },

  {
    countries: [
      { country: "CH", languages: ["en", "de", "fr", "it"] },
      { country: "EC", languages: ["en", "es"] },
      { country: "LV", languages: ["en", "lv"] },
      { country: "BG", languages: ["en", "bg"] },
      { country: "KZ", languages: ["en", "ru"] },
    ],
    date: new Date(2022, 0, 19),
    type: RELEASE_TYPES.V2_ROLLOUT,
    version: "fs2-19.01.2022",
  },

  {
    countries: [
      { country: "CN", languages: ["en", "zh"] },
      { country: "MX", languages: ["en", "es"] },
      { country: "PL", languages: ["en", "pl"] },
    ],
    date: new Date(2022, 0, 26),
    type: RELEASE_TYPES.V2_ROLLOUT,
    version: "fs2-26.01.2022",
  },

  {
    countries: [
      { country: "AT", languages: ["en", "de"] },
      { country: "HK", languages: ["en", "zh"] },
    ],
    date: new Date(2022, 1, 2),
    type: RELEASE_TYPES.V2_ROLLOUT,
    version: "fs2-02.02.2022",
  },

  {
    countries: [{ country: "SE", languages: ["en", "sv"] }],
    date: new Date(2022, 1, 16),
    type: RELEASE_TYPES.V2_ROLLOUT,
    version: "fs2-16.02.2022",
  },

  {
    countries: [{ country: "SG", languages: ["en"] }],
    date: new Date(2022, 1, 23),
    type: RELEASE_TYPES.V2_ROLLOUT,
    version: "fs2-23.02.2022",
  },

  {
    countries: [
      { country: "KR", languages: ["en", "ko"] },
      { country: "NZ", languages: ["en"] },
    ],
    date: new Date(2022, 2, 2),
    type: RELEASE_TYPES.V2_ROLLOUT,
    version: "fs2-02.03.2022",
  },

  {
    date: new Date(2022, 3, 6),
    countries: ["CH", "FR", "GB", "IE", "IT", "MY", "PH", "RO", "TH", "TW"],
    features: [
      "Available Connectors options in pilot countries: Scheduling A Callback, Contact Form and Call In",
      "Customizable callback time slots for Schedule A Callback option",
      "Call In option automatically reacts to and is only available within the defined business hours",
    ],
    isDetailed: false,
    summary:
      "After our successful pilot phase in New Zealand and Singapore in 2021 and early 2022, we now rolled out our Connectors feature to 10 countries in EMEA and APAC! Users now have the opportunity to get in contact with DHL Express in even faster and more direct ways.",
    title: "🍾 Connectors released in Pilot Countries!",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-06.04.2022",
  },

  {
    date: new Date(2022, 3, 13),
    isDetailed: false,
    summary:
      "With the successful rollout to the first countries Sri Lanka and Iraq, we kicked off the Phase IV rollout. In the next weeks, we will roll out FS 2.0 to 34 new countries in total, increasing the global coverage by more than 3% in the process.",
    title: "🍾 PHASE IV Country Rollout started!",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-13.04.2022",
  },

  {
    countries: [
      { country: "LK", languages: ["en"] },
      { country: "IQ", languages: ["en"] },
    ],
    date: new Date(2022, 3, 13),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "phaseIV-cr-13.04.2022",
  },

  {
    countries: [
      { country: "MK", languages: ["en"] },
      { country: "RS", languages: ["en", "sr"] },
    ],
    date: new Date(2022, 3, 20),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "phaseIV-cr-20.04.2022",
  },

  {
    countries: [
      { country: "BA", languages: ["en"] },
      { country: "BD", languages: ["en"] },
    ],
    date: new Date(2022, 3, 27),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "phaseIV-cr-27.04.2022",
  },

  {
    countries: [{ country: "SI", languages: ["en", "sl"] }],
    date: new Date(2022, 4, 4),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "phaseIV-cr-04.05.2022",
  },

  {
    countries: [
      { country: "MU", languages: ["en"] },
      { country: "PG", languages: ["en"] },
    ],
    date: new Date(2022, 4, 18),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "phaseIV-cr-18.05.2022",
  },

  {
    countries: [
      { country: "AL", languages: ["en"] },
      { country: "CY", languages: ["en", "el"] },
      { country: "FJ", languages: ["en"] },
      { country: "LB", languages: ["en"] },
      { country: "SN", languages: ["en", "fr"] },
    ],
    date: new Date(2022, 4, 25),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "phaseIV-cr-25.05.2022",
  },

  {
    date: new Date(2022, 4, 30),
    countries: ["IN", "KR", "VN"],
    isDetailed: false,
    title: "🔌 Connectors release APAC countries",
    summary:
      "Next group of rollouts for our Connectors feature - users now have the opportunity to get in contact with DHL Express in even faster and more direct ways.",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-connectors-30.05.2022",
  },

  {
    countries: [
      { country: "CR", languages: ["en", "es"] },
      { country: "HN", languages: ["en", "es"] },
      { country: "PA", languages: ["en", "es"] },
    ],
    date: new Date(2022, 5, 1),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "phaseIV-cr-01.06.2022",
  },

  {
    countries: [{ country: "NL", languages: ["en", "nl"] }],
    date: new Date(2022, 5, 1),
    type: RELEASE_TYPES.V2_ROLLOUT,
    version: "fs20-cr-01.06.2022",
  },

  {
    countries: [
      { country: "AO", languages: ["en", "pt"] },
      { country: "GA", languages: ["en", "fr"] },
      { country: "GT", languages: ["en", "es"] },
      { country: "MM", languages: ["en"] },
    ],
    date: new Date(2022, 5, 8),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "phaseIV-cr-08.06.2022",
  },

  {
    date: new Date(2022, 5, 13),
    countries: ["AU", "PK", "HK"],
    isDetailed: false,
    title: "🔌 Connectors release APAC countries",
    summary:
      "Next group of rollouts for our Connectors feature - users now have the opportunity to get in contact with DHL Express in even faster and more direct ways.",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-connectors-13.06.2022",
  },

  {
    countries: [
      { country: "ID", languages: ["en"] },
      { country: "OM", languages: ["en"] },
    ],
    date: new Date(2022, 5, 15),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "phaseIV-cr-15.06.2022",
  },

  {
    countries: [{ country: "BE", languages: ["en", "fr", "nl"] }],
    date: new Date(2022, 5, 15),
    type: RELEASE_TYPES.V2_ROLLOUT,
    version: "fs20-cr-15.06.2022",
  },

  {
    countries: [{ country: "MG", languages: ["en", "fr"] }],
    date: new Date(2022, 5, 22),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "phaseIV-cr-22.06.2022",
  },

  {
    date: new Date(2022, 5, 27),
    countries: ["BD", "HR", "ID", "LK", "NO"],
    isDetailed: false,
    title: "🔌 Connectors release APAC & EU countries",
    summary:
      "Next group of rollouts for our Connectors feature - users now have the opportunity to get in contact with DHL Express in even faster and more direct ways.",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-connectors-27.06.2022",
  },

  {
    countries: [{ country: "UG", languages: ["en"] }],
    date: new Date(2022, 5, 29),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "phaseIV-cr-29.06.2022",
  },

  {
    date: new Date(2022, 6, 11),
    countries: ["AT", "ES", "GR", "LT", "SE"],
    isDetailed: false,
    title: "🔌 Connectors release EU countries",
    summary:
      "Next group of rollouts for our Connectors feature - users now have the opportunity to get in contact with DHL Express in even faster and more direct ways.",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-connectors-11.07.2022",
  },

  {
    countries: [{ country: "LU", languages: ["en", "fr", "de"] }],
    date: new Date(2022, 6, 13),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "phaseIV-cr-13.07.2022",
  },

  {
    countries: [{ country: "MZ", languages: ["en", "pt"] }],
    date: new Date(2022, 6, 20),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "phaseIV-cr-20.07.2022",
  },

  {
    date: new Date(2022, 6, 25),
    countries: ["DK", "FI", "PL", "PT", "TR", "US"],
    isDetailed: false,
    title: "🔌 Connectors release US & EU countries",
    summary:
      "Next group of rollouts for our Connectors feature - users now have the opportunity to get in contact with DHL Express in even faster and more direct ways.",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-connectors-25.07.2022",
  },

  {
    countries: [{ country: "ET", languages: ["en"] }],
    date: new Date(2022, 7, 3),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "phaseIV-cr-03.08.2022",
  },

  {
    date: new Date(2022, 7, 8),
    countries: ["AE", "BG", "CZ", "HU", "IL", "SK"],
    isDetailed: false,
    title: "🔌 Connectors release EMEA countries",
    summary:
      "Next group of rollouts for our Connectors feature - users now have the opportunity to get in contact with DHL Express in even faster and more direct ways.",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-connectors-08.08.2022",
  },

  {
    countries: [
      { country: "NI", languages: ["en", "es"] },
      { country: "CM", languages: ["en", "fr"] },
    ],
    date: new Date(2022, 7, 17),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "phaseIV-cr-17.08.2022",
  },

  {
    date: new Date(2022, 7, 22),
    countries: ["EE", "JP", "KZ", "LV"],
    isDetailed: false,
    title: "🔌 Connectors release EMEA & APAC countries",
    summary:
      "Next group of rollouts for our Connectors feature - users now have the opportunity to get in contact with DHL Express in even faster and more direct ways.",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-connectors-22.08.2022",
  },

  {
    countries: [
      { country: "KH", languages: ["en"] },
      { country: "ZW", languages: ["en"] },
    ],
    date: new Date(2022, 8, 14),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "phaseIV-cr-14.09.2022",
  },

  {
    date: new Date(2022, 9, 4),
    countries: ["EG", "KW", "SA"],
    isDetailed: false,
    title: "🔌 Connectors release EMEA countries",
    summary:
      "Next group of rollouts for our Connectors feature - users now have the opportunity to get in contact with DHL Express in even faster and more direct ways.",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-connectors-04.10.2022",
  },

  {
    date: new Date(2022, 9, 17),
    countries: ["AR", "BR", "CA", "CO", "MX"],
    isDetailed: false,
    title: "🔌 Connectors release Americas countries",
    summary:
      "Next group of rollouts for our Connectors feature - users now have the opportunity to get in contact with DHL Express in even faster and more direct ways.",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-connectors-17.10.2022",
  },

  {
    date: new Date(2022, 9, 26),
    countries: ["PL"],
    isDetailed: false,
    title: "🔌 First Connectors release for DHL Parcel",
    summary:
      "The rollout in Poland marks the first rollout of our Connectors feature to DHL Parcel - users now have the opportunity to get in contact with DHL Parcel in even faster and more direct ways.",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-connectors-26.10.2022",
  },

  {
    date: new Date(2022, 9, 31),
    countries: ["PE", "CL"],
    isDetailed: false,
    title: "🔌 Connectors release South America countries",
    summary:
      "Next group of rollouts for our Connectors feature - users now have the opportunity to get in contact with DHL Express in even faster and more direct ways.",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-connectors-31.10.2022",
  },

  {
    countries: [{ country: "CI", languages: ["en", "fr"] }],
    date: new Date(2022, 10, 9),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fs2-09.11.2022",
  },

  {
    countries: [{ country: "SV", languages: ["en", "es"] }],
    date: new Date(2022, 10, 23),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fs2-23.11.2022",
  },

  {
    countries: [{ country: "BA", languages: ["bs"] }],
    date: new Date(2023, 1, 27),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fs2-27.02.2023",
  },

  {
    date: new Date(2023, 1, 6),
    countries: ["GH", "KE", "NG", "ZA"],
    isDetailed: false,
    title: "🔌 Connectors release African countries",
    summary:
      "Next group of rollouts for our Connectors feature - users now have the opportunity to get in contact with DHL Express in even faster and more direct ways.",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-connectors-06.02.2023",
  },
  {
    date: new Date(2023, 4, 30),
    countries: ["BE"],
    isDetailed: false,
    title: "🔌 Connectors release Belgium",
    summary:
      "Next group of rollouts for our Connectors feature - users now have the opportunity to get in contact with DHL Express in even faster and more direct ways.",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-connectors-30.05.2023",
  },
  {
    date: new Date(2023, 8, 8),
    countries: ["BO", "CN"],
    isDetailed: false,
    title: "🔌 Connectors release Bolivia and China",
    summary:
      "Next group of rollouts for our Connectors feature - users now have the opportunity to get in contact with DHL Express in even faster and more direct ways.",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-connectors-08.09.2023",
  },
  {
    date: new Date(2023, 8, 28),
    countries: ["DE"],
    isDetailed: false,
    title: "🔌 DHL Express Connectors release Germany",
    summary:
      "Next group of rollouts for our Connectors feature - users now have the opportunity to get in contact with DHL Express in even faster and more direct ways.",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-connectors-28.09.2023",
  },
  {
    date: new Date(2023, 9, 9),
    countries: ["QA", "SV", "UG"],
    isDetailed: false,
    title: "🔌 Connectors release El Salvador, Qatar, Uganda",
    summary:
      "Next group of rollouts for our Connectors feature - users now have the opportunity to get in contact with DHL Express in even faster and more direct ways.",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-connectors-09.10.2023",
  },
  {
    date: new Date(2023, 9, 11),
    countries: ["DE"],
    isDetailed: false,
    title: "🔌 First DHL Paket Connectors release in Germany!",
    summary:
      "This rollout marks the first time of DHL Paket Connectors getting rolled out - users now have the opportunity to get in contact with DHL Paket in even faster and more direct ways.",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-connectors-11.10.2023",
  },
  {
    date: new Date(2023, 9, 24),
    countries: ["EC", "MA", "CY", "KH", "HN"],
    isDetailed: false,
    title: "🔌 Connectors release",
    summary:
      "Next group of rollouts for our Connectors feature - users now have the opportunity to get in contact with DHL Express in even faster and more direct ways.",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-connectors-24.10.2023",
  },
  {
    date: new Date(2023, 9, 25),
    countries: ["VE", "OM", "ET", "BH", "GT"],
    isDetailed: false,
    title: "🔌 Connectors release",
    summary:
      "Next group of rollouts for our Connectors feature - users now have the opportunity to get in contact with DHL Express in even faster and more direct ways.",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-connectors-25.10.2023",
  },
  {
    date: new Date(2023, 10, 7),
    countries: ["MU", "PA", "AL", "AO", "IQ"],
    isDetailed: false,
    title: "🔌 Connectors release",
    summary:
      "Next group of rollouts for our Connectors feature - users now have the opportunity to get in contact with DHL Express in even faster and more direct ways.",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-connectors-07.11.2023",
  },
  {
    date: new Date(2023, 10, 8),
    countries: ["CI", "FJ", "PG", "ZW", "GA"],
    isDetailed: false,
    title: "🔌 Connectors release",
    summary:
      "Next group of rollouts for our Connectors feature - users now have the opportunity to get in contact with DHL Express in even faster and more direct ways.",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-connectors-08.11.2023",
  },
  {
    date: new Date(2023, 10, 16),
    countries: ["CM", "NI", "SN", "MZ", "BA", "MG"],
    isDetailed: false,
    title: "🔌 Connectors release",
    summary:
      "Next group of rollouts for our Connectors feature - users now have the opportunity to get in contact with DHL Express in even faster and more direct ways.",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-connectors-16.11.2023",
  },
  {
    countries: [{ country: "IL", languages: ["he"] }],
    date: new Date(2023, 10, 16),
    title: "New Hebrew Language Versions Added",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fs2-16.11.2023",
  },
  {
    countries: [
      { country: "BN", languages: ["en"] },
      { country: "DO", languages: ["en", "es"] },
      { country: "JM", languages: ["en"] },
      { country: "JO", languages: ["en"] },
      { country: "NP", languages: ["en"] },
      { country: "UY", languages: ["en", "es"] },
    ],
    date: new Date(2024, 0, 16),
    title: "New Countries Added (Batch 14)",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fs2-16.01.2024",
  },
  {
    countries: [
      { country: "SA", languages: ["ar"] },
      { country: "LB", languages: ["ar"] },
    ],
    date: new Date(2024, 1, 1),
    title: "New Arabic Language Versions Added",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fs2-01.02.2024",
  },
  {
    countries: [
      { country: "BM", languages: ["en"] },
      { country: "BS", languages: ["en"] },
      { country: "BT", languages: ["en"] },
      { country: "KY", languages: ["en"] },
      { country: "LA", languages: ["en"] },
      { country: "MQ", languages: ["en", "fr"] },
      { country: "MV", languages: ["en"] },
      { country: "PY", languages: ["en", "es"] },
      { country: "TT", languages: ["en"] },
    ],
    date: new Date(2024, 1, 20),
    title: "New Countries Added (Batches 15 & 16)",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fs2-20.02.2024",
  },
  {
    countries: [
      { country: "BZ", languages: ["en", "es"] },
      { country: "RE", languages: ["en", "fr"] },
      { country: "TL", languages: ["en"] },
      { country: "VG", languages: ["en"] },
    ],
    date: new Date(2024, 1, 27),
    title: "New Countries Added (Batch 17)",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fs2-27.02.2024",
  },
  {
    countries: [
      { country: "BH", languages: ["ar"] },
      { country: "AE", languages: ["ar"] },
    ],
    date: new Date(2024, 2, 7),
    title: "New Arabic Language Versions Added",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fs2-07.03.2024",
  },
  {
    countries: [
      { country: "CD", languages: ["en", "fr"] },
      { country: "GF", languages: ["en", "fr"] },
      { country: "LS", languages: ["en"] },
    ],
    date: new Date(2024, 2, 26),
    title: "New Countries Added (Batch 18)",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fs2-26.03.2024",
  },
  {
    countries: [
      { country: "BB", languages: ["en"] },
      { country: "GQ", languages: ["en", "es"] },
      { country: "IS", languages: ["en"] },
      { country: "MT", languages: ["en"] },
      { country: "YE", languages: ["en"] },
    ],
    date: new Date(2024, 3, 10),
    title: "New Countries Added (Batch 19)",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fs2-10.04.2024",
  },
  {
    countries: [
      { country: "BW", languages: ["en"] },
      { country: "HT", languages: ["en", "fr"] },
      { country: "MO", languages: ["en"] },
      { country: "MW", languages: ["en"] },
      { country: "SY", languages: ["en"] },
    ],
    date: new Date(2024, 3, 23),
    title: "New Countries Added (Batch 20)",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fs2-23.04.2024",
  },
  {
    countries: [{ country: "JO", languages: ["ar"] }],
    date: new Date(2024, 3, 23),
    title: "New Arabic Language Version Added",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fs2-23.04.2024-AR",
  },
  {
    date: new Date(2024, 3, 29),
    countries: ["US"],
    isDetailed: false,
    title: "🔌 First Connectors release for DHL Global Forwarding!",
    summary:
      "The rollout of the Schedule A Callback Connector in the United States marks the first rollout of our Connectors feature to DHL Global Forwarding - users now have the opportunity to get in contact in even faster and more direct ways.",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-connectors-dgf-29.04.2024",
  },
  {
    countries: [{ country: "DZ", languages: ["en", "fr"] }],
    date: new Date(2024, 4, 21),
    title: "New Country Added (Batch 22)",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fs2-21.05.2024",
  },
  {
    countries: [{ country: "GP", languages: ["en", "fr"] }],
    date: new Date(2024, 5, 4),
    title: "New Country Added (Batch 23)",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fs2-04.06.2024",
  },
  {
    countries: [{ country: "TZ", languages: ["en"] }],
    date: new Date(2024, 5, 18),
    title: "New Country Added (Batch 24)",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fs2-18.06.2024",
  },
  {
    countries: [
      { country: "CG", languages: ["en", "fr"] },
      { country: "GM", languages: ["en"] },
      { country: "ML", languages: ["en", "fr"] },
      { country: "NA", languages: ["en"] },
      { country: "SL", languages: ["en"] },
      { country: "SD", languages: ["en"] },
    ],
    date: new Date(2024, 6, 30),
    title: "New Country Added (Batch 27)",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fs2-30.07.2024",
  },
  {
    countries: [
      { country: "BJ", languages: ["en", "fr"] },
      { country: "SC", languages: ["en", "fr"] },
      { country: "SS", languages: ["en"] },
      { country: "SZ", languages: ["en"] },
      { country: "ZM", languages: ["en"] },
    ],
    date: new Date(2024, 7, 13),
    title: "New Country Added (Batch 28)",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fs2-13.08.2024",
  },
  {
    date: new Date(2024, 7, 13),
    countries: ["RS"],
    isDetailed: false,
    title: "🔌 Connectors release",
    summary:
      "Next rollout for our Connectors feature - users now have the opportunity to get in contact with DHL Express in even faster and more direct ways.",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "fs2-connectors-13.08..2024",
  },
].sort((a, b) => b.date - a.date);

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default reducer;

export const get = identity;
