import { curry } from "ramda";

const REMOTE_DATA_TYPE = {
  NOT_ASKED: "Not Asked",
  LOADING: "Loading",
  SUCCESS: "Success",
  ERROR: "Error",
};

const NOOP = Function.prototype;

const RemoteData = {
  chain: (chainFun, remoteData) =>
    remoteData.type === REMOTE_DATA_TYPE.SUCCESS
      ? chainFun(remoteData.data, remoteData.correlationId)
      : remoteData,

  error: (error, correlationId = null) => ({
    correlationId,
    error,
    type: REMOTE_DATA_TYPE.ERROR,
  }),

  notAsked: () => ({
    type: REMOTE_DATA_TYPE.NOT_ASKED,
  }),

  loading: (correlationId = null) => ({
    correlationId,
    type: REMOTE_DATA_TYPE.LOADING,
  }),

  success: (data, correlationId = null) => ({
    correlationId,
    data,
    type: REMOTE_DATA_TYPE.SUCCESS,
  }),

  case: curry((caseObj, remoteData) => {
    const { error, loading, notAsked, success, _ = NOOP } = caseObj;

    if (success && RemoteData.isSuccess(remoteData)) {
      return success(remoteData.data, remoteData.correlationId);
    }

    if (loading && RemoteData.isLoading(remoteData)) {
      return loading(remoteData.correlationId);
    }

    if (notAsked && RemoteData.isNotAsked(remoteData)) {
      return notAsked();
    }

    if (error && RemoteData.isError(remoteData)) {
      return error(remoteData.error, remoteData.correlationId);
    }

    return _();
  }),

  map: curry((mapFun, remoteData) =>
    remoteData.type === REMOTE_DATA_TYPE.SUCCESS
      ? RemoteData.success(mapFun(remoteData.data), remoteData.correlationId)
      : remoteData
  ),

  withDefault: curry((defaultValue, remoteData) =>
    remoteData.type === REMOTE_DATA_TYPE.SUCCESS
      ? remoteData.data
      : defaultValue
  ),

  getCorrelationId: (remoteData) => remoteData.correlationId,

  isNotAsked: (remoteData) => remoteData.type === REMOTE_DATA_TYPE.NOT_ASKED,

  isLoading: (remoteData) => remoteData.type === REMOTE_DATA_TYPE.LOADING,

  isSuccess: (remoteData) => remoteData.type === REMOTE_DATA_TYPE.SUCCESS,

  isError: (remoteData) => remoteData.type === REMOTE_DATA_TYPE.ERROR,
};

export default RemoteData;
