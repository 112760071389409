import { identity } from "ramda";

const INITIAL_STATE = [
  "AU",
  "IT",
  "MX",
  "NL",
  "ZA",
  "CN",
  "FR",
  "KR",
  "US",
  "GB",
];

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default reducer;

export const get = identity;
