import { identity } from "ramda";
import {
  GET_GAQ_ANALYTICS,
  GET_GAQ_ANALYTICS_FAILURE,
  GET_GAQ_ANALYTICS_SUCCESS,
} from "../../../../../actions";
import RD from "../../../../../utils/functional/remote-data";

const INITIAL_STATE = RD.notAsked();

const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_GAQ_ANALYTICS:
      return RD.loading();

    case GET_GAQ_ANALYTICS_SUCCESS:
      return RD.success(payload);

    case GET_GAQ_ANALYTICS_FAILURE:
      return RD.error(payload);

    default:
      return state;
  }
};

export default reducer;

export const get = identity;
