import { combineReducers } from "redux";
import rolloutCountriesReducer, * as rolloutCountries from "./rollout-countries";
import countrySettingsReducer, * as countrySettings from "./country-settings";
import deleteGAQReleaseNoteReducer, * as deleteReleaseNoteStatus from "./delete-release";
import releaseNotesReducer, * as releaseNotes from "./releasesFromApi";
import currentReleaseReducer, * as currentRelease from "./currentRelease";
import analyticsReducer, * as analytics from "./analytics";

export default combineReducers({
  analytics: analyticsReducer,
  countrySettings: countrySettingsReducer,
  rolloutCountries: rolloutCountriesReducer,
  releaseNotes: releaseNotesReducer,
  currentRelease: currentReleaseReducer,
  deleteReleaseNoteStatus: deleteGAQReleaseNoteReducer,
});

export const getCountrySettings = (state, country) =>
  countrySettings.getCountrySettings(state.countrySettings, country);

export const getCountryField = (state) =>
  countrySettings.getCountryField(state.countrySettings);

export const getLastSelectedCountry = (state) =>
  countrySettings.getLastSelectedCountry(state.countrySettings);

export const getGAQReleaseNotes = (state) =>
  releaseNotes.get(state.releaseNotes);

export const getReleaseNote = (state) =>
  currentRelease.get(state.currentRelease);

export const getDeleteReleaseNote = (state) =>
  deleteReleaseNoteStatus.get(state.deleteReleaseNoteStatus);

export const getRolloutCountries = (state) =>
  rolloutCountries.get(state.rolloutCountries);

export const getAnalyticsData = (state) => analytics.getData(state.analytics);

export const getAnalyticsFilter = (state) =>
  analytics.getFilter(state.analytics);
