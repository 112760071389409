/* eslint-disable no-bitwise */
/* eslint-disable no-param-reassign */

import { always, compose, propEq, reduce } from "ramda";
import { deburr, escapeRegExp } from "lodash-es";
import produce from "immer";
import {
  DROP_DRAGGED_BUSINESS_UNIT,
  GET_BUSINESS_UNITS_FAILURE,
  GET_BUSINESS_UNITS_SUCCESS,
  GET_BUSINESS_UNITS,
  GET_FS_COUNTRY_CONFIGURATION_FAILURE,
  GET_FS_COUNTRY_CONFIGURATION_SUCCESS,
  GET_FS_COUNTRY_CONFIGURATION,
  GET_FS_COUNTRY_SETTINGS_FAILURE,
  GET_FS_COUNTRY_SETTINGS_SUCCESS,
  GET_FS_COUNTRY_SETTINGS,
  GET_RELEASED_COUNTRIES_FAILURE,
  GET_RELEASED_COUNTRIES_SUCCESS,
  GET_RELEASED_COUNTRIES,
  QUERY_FS_COUNTRY,
  REMOVE_COUNTRY_SETTINGS_BUSINESS_UNIT,
  REMOVE_DRAGGING_BUSINESS_UNIT_DROP_ZONE,
  SAVE_COUNTRY_SETTINGS_FAILURE,
  SAVE_COUNTRY_SETTINGS_SUCCESS,
  SAVE_COUNTRY_SETTINGS,
  SELECT_FS_COUNTRY,
  SET_COUNTRY_SETTINGS_EDIT_MODE,
  SET_FS_COUNTRY,
  SET_DRAGGING_BUSINESS_UNIT_DROP_ZONE,
  SET_NEW_COUNTRY_SETTINGS,
  START_DRAGGING_BUSINESS_UNIT,
} from "../../../../actions";
import {
  ROUTES,
  PRODUCTS,
  REGIONS,
  FS_COUNTRIES,
} from "../../../../utils/constants";
import RD from "../../../../utils/functional/remote-data";
import CS from "../../../../utils/functional/country-settings";
import AC from "../../../../utils/functional/auto-complete";
import FF from "../../../../utils/functional/form-field";
import * as reducers from "../../..";

const saveCountrySettings = () => ({
  type: SAVE_COUNTRY_SETTINGS,
});

const saveCountrySettingsSuccess = (country) => (countrySettings) => ({
  payload: {
    country,
    countrySettings,
  },
  type: SAVE_COUNTRY_SETTINGS_SUCCESS,
});

const saveCountrySettingsFailure = (country) => (error) => ({
  error: true,
  payload: {
    country,
    error,
  },
  type: SAVE_COUNTRY_SETTINGS_FAILURE,
});

const getReleasedCountries = () => ({
  type: GET_RELEASED_COUNTRIES,
});

const getReleasedCountriesSuccess = (payload) => ({
  payload,
  type: GET_RELEASED_COUNTRIES_SUCCESS,
});

const getReleasedCountriesFailure = (payload) => ({
  error: true,
  payload,
  type: GET_RELEASED_COUNTRIES_FAILURE,
});

const getBusinessUnits = () => ({
  type: GET_BUSINESS_UNITS,
});

const getBusinessUnitsSuccess = (payload) => ({
  payload,
  type: GET_BUSINESS_UNITS_SUCCESS,
});

const getBusinessUnitsFailure = (payload) => ({
  error: true,
  payload,
  type: GET_BUSINESS_UNITS_FAILURE,
});

const getCountryConfiguration = (country, profile) => ({
  payload: { country, profile },
  type: GET_FS_COUNTRY_CONFIGURATION,
});

const getCountryConfigurationSuccess =
  (country, profile) => (countryConfiguration) => ({
    payload: { country, profile, countryConfiguration },
    type: GET_FS_COUNTRY_CONFIGURATION_SUCCESS,
  });

const getCountryConfigurationFailure = (country, profile) => (error) => ({
  error: true,
  payload: { country, profile, error },
  type: GET_FS_COUNTRY_CONFIGURATION_FAILURE,
});

const getCountrySettings = (country) => ({
  payload: country,
  type: GET_FS_COUNTRY_SETTINGS,
});

const getCountrySettingsSuccess = (country) => (countrySettings) => ({
  payload: { country, countrySettings },
  type: GET_FS_COUNTRY_SETTINGS_SUCCESS,
});

const getCountrySettingsFailure = (country) => (error) => ({
  error: true,
  payload: { country, error },
  type: GET_FS_COUNTRY_SETTINGS_FAILURE,
});

const normalizeThreshold = (threshold) =>
  threshold &&
  threshold.reduce(
    (allThresholds, { timeInterval, numberOfShipments }) => ({
      ...allThresholds,
      [`${timeInterval.toLowerCase()}Shipments`]: numberOfShipments,
    }),
    {}
  );

const normalizeOptions = (options) =>
  Object.entries(options).reduce(
    (acc, [key, value]) => (value ? [...acc, key.toUpperCase()] : acc),
    []
  );

const addBusinessUnitToRegion = (allRegions, region, businessUnit) =>
  produce(allRegions, (draft) => {
    draft[region] = draft[region] || [];

    const {
      businessUnitId,
      salesLeadQualified,
      selfOnBoardingUrls,
      options,
      threshold,
    } = businessUnit;

    draft[region].push({
      id: businessUnitId,
      salesLeadQualified,
      selfOnBoardingUrls,
      options: options ? normalizeOptions(options) : [],
      threshold: normalizeThreshold(threshold),
    });
  });

const decodeProduct = ({ shipmentScales }) => ({
  ...shipmentScales.reduce(
    (allRegions, { shipmentScaleId, businessUnits }) => ({
      ...allRegions,
      ...businessUnits.reduce(
        // I want to shadow below just to illustrate that is the same structure
        // being passed down.
        //
        // eslint-disable-next-line no-shadow
        (allRegions, bu) =>
          addBusinessUnitToRegion(allRegions, shipmentScaleId, bu),
        allRegions
      ),
    }),
    {}
  ),
});

const encodeThreshold = (threshold) => [
  { numberOfShipments: threshold.dailyShipments, timeInterval: "DAILY" },
  { numberOfShipments: threshold.weeklyShipments, timeInterval: "WEEKLY" },
  { numberOfShipments: threshold.monthlyShipments, timeInterval: "MONTHLY" },
];

const encodeOptions = reduce(
  (acc, option) => ({ ...acc, [option.toLowerCase()]: true }),
  {}
);

const encodeScale = (shipmentScaleId, businessUnits) =>
  businessUnits.length > 0
    ? {
        shipmentScaleId,
        businessUnits: businessUnits.map(({ id, ...bu }) => ({
          ...bu,
          businessUnitId: id,
          options:
            bu.options.length > 0 ? encodeOptions(bu.options) : undefined,

          salesLeadQualified:
            bu.salesLeadQualified == null || bu.salesLeadQualified,

          selfOnBoardingUrls: bu.selfOnBoardingUrls || [],
          threshold: bu.threshold && encodeThreshold(bu.threshold),
        })),
      }
    : undefined;

const encodeProduct = (productId, data) => ({
  productId,
  shipmentScales: [
    encodeScale(REGIONS.REGIONAL, data.REGIONAL),
    encodeScale(REGIONS.GLOBAL, data.GLOBAL),
    encodeScale(REGIONS.DOMESTIC, data.DOMESTIC),
  ].filter(Boolean),
});

const encodeCountrySettings = (country) => (countrySettings) => ({
  id: countrySettings.id,
  countryCode: country,
  ...(countrySettings.qualifier && { qualifier: countrySettings.qualifier }),
  shipmentProducts: [
    encodeProduct(PRODUCTS.FREIGHT, countrySettings.FREIGHT),
    encodeProduct(PRODUCTS.MAIL, countrySettings.MAIL),
    encodeProduct(PRODUCTS.PARCEL, countrySettings.PARCEL),
  ],
});

const getShipmentProducts = (shipmentProducts, qualifier, name = "") => {
  if (!name || !qualifier) {
    return shipmentProducts;
  }

  const qualifiedShipmentProducts = qualifier.find((q) => q.name === name);

  return qualifiedShipmentProducts?.shipmentProducts || [];
};

const mapProductsToProps = ({ matchedShipmentProducts }) => {
  const byProduct = propEq("productId");
  const freight = matchedShipmentProducts.find(byProduct(PRODUCTS.FREIGHT));
  const mail = matchedShipmentProducts.find(byProduct(PRODUCTS.MAIL));
  const parcel = matchedShipmentProducts.find(byProduct(PRODUCTS.PARCEL));

  const emptyProduct = {
    [REGIONS.DOMESTIC]: [],
    [REGIONS.GLOBAL]: [],
    [REGIONS.REGIONAL]: [],
  };

  const mergeWithEmptyProduct = (product) => ({
    ...emptyProduct,
    ...product,
  });

  const result = {
    [PRODUCTS.FREIGHT]: freight
      ? mergeWithEmptyProduct(decodeProduct(freight))
      : emptyProduct,

    [PRODUCTS.MAIL]: mail
      ? mergeWithEmptyProduct(decodeProduct(mail))
      : emptyProduct,

    [PRODUCTS.PARCEL]: parcel
      ? mergeWithEmptyProduct(decodeProduct(parcel))
      : emptyProduct,
  };

  return result;
};

const mapQualifier = (qualifier) => {
  if (!qualifier || qualifier.length === 0) return [];

  return qualifier.map((el) => {
    const { shipmentProducts, name } = el;
    const matchedShipmentProducts = getShipmentProducts(
      shipmentProducts,
      qualifier,
      name
    );

    return {
      ...el,
      ...mapProductsToProps({ matchedShipmentProducts }),
    };
  });
};

const decodeCountrySettings = (countrySettings) => {
  const { shipmentProducts, id, qualifier } = countrySettings;
  const matchedShipmentProducts = getShipmentProducts(
    shipmentProducts,
    qualifier
  );
  const mappedQualifier = mapQualifier(qualifier);

  return {
    id,
    ...(mappedQualifier?.length && { qualifier: [...mappedQualifier] }),
    ...mapProductsToProps({ matchedShipmentProducts }),
  };
};

const normalizeValueForSearch = (input) => deburr(input.trim().toLowerCase());

const queryFSCountry = (payload) => ({
  payload,
  type: QUERY_FS_COUNTRY,
});

const setFSCountry = (payload) => ({
  payload,
  type: SET_FS_COUNTRY,
});

export const setNewCountrySettings = (payload) => ({
  payload,
  type: SET_NEW_COUNTRY_SETTINGS,
});

export const setEditMode = () => ({
  type: SET_COUNTRY_SETTINGS_EDIT_MODE,
});

export const startDraggingBusinessUnit = (id, origin) => ({
  payload: {
    id,
    origin,
  },
  type: START_DRAGGING_BUSINESS_UNIT,
});

export const dropDraggedBusinessUnit = (isCopy) => ({
  payload: isCopy,
  type: DROP_DRAGGED_BUSINESS_UNIT,
});

export const removeCountrySettingBusinessUnit = (id, product, region) => ({
  payload: { id, product, region },
  type: REMOVE_COUNTRY_SETTINGS_BUSINESS_UNIT,
});

export const removeDraggingBusinessUnitDropZone = () => ({
  type: REMOVE_DRAGGING_BUSINESS_UNIT_DROP_ZONE,
});

export const setDraggedBusinessUnitDropZone = (product, region) => ({
  payload: { product, region },
  type: SET_DRAGGING_BUSINESS_UNIT_DROP_ZONE,
});

export const selectFSCountryEffect =
  (country) =>
  (dispatch, getState, { history, window }) => {
    const countryField = reducers.getFSCountryField(getState());

    if (AC.getValue(countryField) === country) {
      return;
    }

    dispatch({ type: SELECT_FS_COUNTRY, payload: country });

    const url = `${ROUTES.FS_COUNTRY_CONFIGURATIONS}/${country}`;

    if (!window.location.pathname.includes(url)) {
      history.push(`${ROUTES.FS_COUNTRY_CONFIGURATIONS}/${country}`);
    }
  };

export const queryFSCountryEffect = (payload) => (dispatch) => {
  dispatch(queryFSCountry(payload));
  const normalizedInput = normalizeValueForSearch(payload);

  const countryNameRegex = new RegExp(
    `${escapeRegExp(`${normalizedInput}`)}.*`,
    "i"
  );

  const options = FS_COUNTRIES.filter(([, label]) =>
    normalizeValueForSearch(label).match(countryNameRegex)
  );

  const selectedCountry = options.find(
    ([, label]) => normalizeValueForSearch(label) === normalizedInput
  );

  const optionsValues = options.map(([id, value]) => ({
    id,
    value,
  }));

  const shouldSelectCountry = selectedCountry && options.length === 1;

  if (shouldSelectCountry) {
    dispatch(selectFSCountryEffect(selectedCountry[0]));

    return;
  }

  dispatch(setFSCountry(AC.unselected(payload, optionsValues)));
};

export const getFSCountryConfigurationEffect =
  (country, profile) =>
  (dispatch, getState, { api }) => {
    const countryConfiguration = reducers.getFSCountryConfiguration(
      getState(),
      country,
      profile
    );

    if (
      countryConfiguration &&
      (RD.isSuccess(countryConfiguration) || RD.isLoading(countryConfiguration))
    ) {
      return;
    }

    dispatch(getCountryConfiguration(country, profile));

    api
      .getFSCountryConfiguration(country, profile)
      .then(compose(dispatch, getCountryConfigurationSuccess(country, profile)))
      .catch(
        compose(dispatch, getCountryConfigurationFailure(country, profile))
      );
  };

export const getFSCountrySettingsEffect =
  (country) =>
  (dispatch, getState, { api }) => {
    const countrySettings = reducers.getFSCountrySettings(getState(), country);

    if (
      countrySettings &&
      CS.case(
        {
          existing: (settings) =>
            RD.isSuccess(settings) || RD.isLoading(settings),
          fresh: always(true),
        },
        countrySettings
      )
    ) {
      return;
    }

    dispatch(getCountrySettings(country));

    api
      .getFSCountrySettings(country)
      .then(
        compose(
          dispatch,
          getCountrySettingsSuccess(country),
          decodeCountrySettings
        )
      )
      .catch(compose(dispatch, getCountrySettingsFailure(country)));
  };

export const saveCountrySettingsEffect =
  () =>
  (dispatch, getState, { api }) => {
    const country = reducers.getFSLastSelectedCountry(getState());
    const accessToken = RD.withDefault("", reducers.getLoggedUser(getState()));

    const pushFormFieldSettingsToApi = (method) =>
      compose(
        (settings) => {
          dispatch(saveCountrySettings());

          api
            .saveCountrySettings(accessToken, settings, method)
            .then(
              compose(
                dispatch,
                saveCountrySettingsSuccess(country),
                decodeCountrySettings
              )
            )
            .catch(compose(dispatch, saveCountrySettingsFailure(country)));
        },
        encodeCountrySettings(country),
        FF.getValue
      );

    CS.case(
      {
        fresh: pushFormFieldSettingsToApi("POST"),
        existing: RD.case({
          success: pushFormFieldSettingsToApi("PUT"),
        }),
      },
      reducers.getFSCountrySettings(getState(), country)
    );
  };

export const getReleasedCountriesEffect =
  () =>
  (dispatch, getState, { api }) => {
    const releasedCountries = reducers.getFSReleasedCountries(getState());

    if (RD.isSuccess(releasedCountries) || RD.isLoading(releasedCountries)) {
      return;
    }

    dispatch(getReleasedCountries());

    api
      .getReleasedCountries()
      .then(compose(dispatch, getReleasedCountriesSuccess))
      .catch(compose(dispatch, getReleasedCountriesFailure));
  };

export const getBusinessUnitsEffect =
  () =>
  (dispatch, getState, { api }) => {
    const businessUnits = reducers.getBusinessUnits(getState());

    if (RD.isSuccess(businessUnits) || RD.isLoading(businessUnits)) {
      return;
    }

    dispatch(getBusinessUnits());

    api
      .getBusinessUnits()
      .then(compose(dispatch, getBusinessUnitsSuccess))
      .catch(compose(dispatch, getBusinessUnitsFailure));
  };
