import { createGlobalStyle } from "styled-components";
import DeliveryWCdLtWoff2 from "@dhl-official/react-ui-library/dist/assets/fonts/Delivery_W_CdLt.woff2";
import DeliveryWRgWoff2 from "@dhl-official/react-ui-library/dist/assets/fonts/Delivery_W_Rg.woff2";
import DeliveryWBdWoff2 from "@dhl-official/react-ui-library/dist/assets/fonts/Delivery_W_Bd.woff2";
import DeliveryWCdBlkWoff2 from "@dhl-official/react-ui-library/dist/assets/fonts/Delivery_W_CdBlk.woff2";

export const MAX_WIDTH = "1200px";
export const BASE_FONT_SIZE = 16;
export const PLANNED_RELEASE_COLOR = "#1976D2";
export const PARTIALLY_RELEASED_COLOR = "#FFCC00";
export const RELEASED_COLOR = "#007C39";
export const NOT_PLANNED_COLOR = "#CCC";

const Reset = createGlobalStyle`
  body {
    font-family: Delivery, Verdana, sans-serif;
    font-size: ${BASE_FONT_SIZE}px;
    margin: 0;
    overflow-x: hidden;
    padding: 0;
  }

  @font-face {
    font-family: "Delivery";
    font-display: block;
    src: url(${DeliveryWCdLtWoff2}) format("woff2");
    font-weight: 200;
  }

  @font-face {
    font-family: "Delivery";
    font-display: block;
    src: url(${DeliveryWRgWoff2}) format("woff2");
    font-weight: 400;
  }

  @font-face {
    font-family: "Delivery";
    font-display: block;
    src: url(${DeliveryWBdWoff2}) format("woff2");
    font-weight: 700;
  }

  @font-face {
    font-family: "Delivery";
    font-display: block;
    src: url(${DeliveryWCdBlkWoff2}) format("woff2");
    font-weight: 800;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .visually-hidden {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px !important;
    overflow: hidden;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
  }

  .PL-Modal__Overlay {
    -webkit-box-align: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
    inset: 0px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    opacity: 0;
    position: fixed;
    transition: opacity 250ms ease 0s;
    z-index: 99999;
  }

  .PL-Modal__Content {
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px 0px, rgb(0 0 0 / 10%) 0px 2px 12px 0px, rgb(0 0 0 / 10%) 0px 0px 1px 0px;
    outline: transparent solid 2px;
    padding: calc(3.9375rem) calc(6.5625rem);
    position: relative;
    transform: translateY(-25px);
    transition: outline-color 250ms ease 0s, transform 250ms ease 0s;
  }
`;

export default Reset;
