import { combineReducers } from "redux";
import frequentShipmentReducer, * as frequentShipment from "./frequent-shipment";
import frequentShipmentFsxReducer, * as frequentShipmentFsx from "./frequent-shipment-fsx";
import getAQuoteReducer, * as getAQuote from "./get-a-quote";
import shipNowReducer, * as shipNow from "./ship-now";
import rolloutMetaReducer, * as rolloutMeta from "./rollout-meta";
import businessUnitsReducer, * as businessUnits from "./business-units";

export default combineReducers({
  frequentShipment: frequentShipmentReducer,
  getAQuote: getAQuoteReducer,
  shipNow: shipNowReducer,
  rolloutMeta: rolloutMetaReducer,
  businessUnits: businessUnitsReducer,
  frequentShipmentFsx: frequentShipmentFsxReducer,
});

export const getFSAnalyticsData = (state) =>
  frequentShipment.getAnalyticsData(state.frequentShipment);

export const getFSAnalyticsFilter = (state) =>
  frequentShipment.getAnalyticsFilter(state.frequentShipment);

export const getGAQAnalyticsData = (state) =>
  getAQuote.getAnalyticsData(state.getAQuote);

export const getGAQAnalyticsFilter = (state) =>
  getAQuote.getAnalyticsFilter(state.getAQuote);

export const getFSCountryConfiguration = (state, country, profile) =>
  frequentShipment.getCountryConfiguration(
    state.frequentShipment,
    country,
    profile
  );

export const getFSCountrySettings = (state, country) =>
  frequentShipment.getCountrySettings(state.frequentShipment, country);

export const getFSConnectorsOverviewConfig = (state) =>
  frequentShipment.getConnectorsOverviewConfig(state.frequentShipment);

export const getFSConnectorsOverviewReleaseDates = (state) =>
  frequentShipment.getConnectorsOverviewReleaseDates(state.frequentShipment);

export const getFSCountryField = (state) =>
  frequentShipment.getCountryField(state.frequentShipment);

export const getFSDraggedBusinessUnit = (state) =>
  frequentShipment.getDraggedBusinessUnit(state.frequentShipment);

export const getFSIsEditMode = (state) =>
  frequentShipment.getIsEditMode(state.frequentShipment);

export const getFSReleasedCountries = (state) =>
  frequentShipment.getReleasedCountries(state.frequentShipment);

export const getFSLastSelectedCountry = (state) =>
  frequentShipment.getLastSelectedCountry(state.frequentShipment);

export const getBusinessUnits = (state) =>
  businessUnits.get(state.businessUnits);

export const getFSReleases = (state) =>
  frequentShipment.getReleases(state.frequentShipment);

export const getFSXReleases = (state) =>
  frequentShipmentFsx.getReleases(state.frequentShipmentFsx);

export const getFSRolloutCountries = (state) =>
  frequentShipment.getRolloutCountries(state.frequentShipment);

export const getCJReleaseStatus = (state) =>
  rolloutMeta.getReleaseStatus(state.rolloutMeta);

export const getCJMapDisplayMode = (state) =>
  rolloutMeta.getMapDisplayMode(state.rolloutMeta);

export const getCJMapRegion = (state) =>
  rolloutMeta.getMapRegion(state.rolloutMeta);

export const getGAQRolloutCountries = (state) =>
  getAQuote.getRolloutCountries(state.getAQuote);

export const getGAQCountrySettings = (state, country) =>
  getAQuote.getCountrySettings(state.getAQuote, country);

export const getGAQCountryField = (state) =>
  getAQuote.getCountryField(state.getAQuote);

export const getGAQLastSelectedCountry = (state) =>
  getAQuote.getLastSelectedCountry(state.getAQuote);

export const getSNReleases = (state) => shipNow.getReleases(state.shipNow);

export const getSNRolloutCountries = (state) =>
  shipNow.getRolloutCountries(state.shipNow);

export const getGAQReleaseNotes = (state) =>
  getAQuote.getGAQReleaseNotes(state.getAQuote);

export const getGAQReleaseNote = (state) =>
  getAQuote.getReleaseNote(state.getAQuote);

export const getDeleteReleaseNote = (state) =>
  getAQuote.getDeleteReleaseNote(state.getAQuote);
