import { curry, flip, prop, propEq } from "ramda";

const NOOP = Function.prototype;

const FORM_FIELD_TYPE = "FormField";

const FORM_FIELD_DATA_TAG = {
  INVALID: "Invalid",
  PRISTINE: "Pristine",
  UNCHECKED: "Unchecked",
  VALID: "Valid",
};

const VALIDATIONS = {
  GROUP_REQUIRED: "group-required",
  IN_DATASET: "in-data-set",
  NUMBER_RANGE: "range",
  PATTERN: "pattern",
  REQUIRED: "required",
};

const Validation = {
  required: (message, path = null) => [VALIDATIONS.REQUIRED, { message, path }],

  pattern: (message, match, path = null) => [
    VALIDATIONS.PATTERN,
    { message, match, path },
  ],

  numberRange: (min, max, minMessage, maxMessage, path = null) => [
    VALIDATIONS.NUMBER_RANGE,
    { min, max, minMessage, maxMessage, path },
  ],

  groupRequired: (message, path = null) => [
    VALIDATIONS.GROUP_REQUIRED,
    { message, path },
  ],

  inDataset: (message, dataset, path = null) => [
    VALIDATIONS.IN_DATASET,
    {
      message,
      dataset: dataset.reduce((acc, cur) => ({ ...acc, [cur]: true }), {}),
      path,
    },
  ],
};

const Meta = {
  isRequired: (meta) =>
    meta.validations.some(
      ([validation]) => validation === VALIDATIONS.REQUIRED
    ),

  create: curry((validations, id) => ({
    id,
    // Can't use function's default value syntax as it breaks currying (NO `(validations = [], id) =>` ALLOWED)
    validations: validations || [],
  })),
};

const FormField = {
  isInvalid: propEq("tag", FORM_FIELD_DATA_TAG.INVALID),

  isPristine: propEq("tag", FORM_FIELD_DATA_TAG.PRISTINE),

  isUnchecked: propEq("tag", FORM_FIELD_DATA_TAG.UNCHECKED),

  isValid: propEq("tag", FORM_FIELD_DATA_TAG.VALID),

  isFormField: propEq("type", FORM_FIELD_TYPE),

  invalid: curry((feedback, meta, value) => ({
    feedback,
    meta,
    tag: FORM_FIELD_DATA_TAG.INVALID,
    type: FORM_FIELD_TYPE,
    value,
  })),

  pristine: curry((meta, value) => ({
    meta,
    tag: FORM_FIELD_DATA_TAG.PRISTINE,
    type: FORM_FIELD_TYPE,
    value,
  })),

  unchecked: curry((meta, value) => ({
    meta,
    tag: FORM_FIELD_DATA_TAG.UNCHECKED,
    type: FORM_FIELD_TYPE,
    value,
  })),

  valid: curry((meta, value) => ({
    meta,
    tag: FORM_FIELD_DATA_TAG.VALID,
    type: FORM_FIELD_TYPE,
    value,
  })),

  case: curry((caseObj, formField) => {
    const { valid, invalid, unchecked, pristine, _ = NOOP } = caseObj;

    if (valid && FormField.isValid(formField)) {
      return valid(formField.value, formField.meta);
    }

    if (invalid && FormField.isInvalid(formField)) {
      return invalid(formField.value, formField.feedback, formField.meta);
    }

    if (unchecked && FormField.isUnchecked(formField)) {
      return unchecked(formField.value, formField.meta);
    }

    if (pristine && FormField.isPristine(formField)) {
      return pristine(formField.value, formField.meta);
    }

    return _();
  }),

  chain: curry((chainFun, formField) =>
    FormField.case(
      {
        invalid: (data, feedback, meta) => chainFun(meta, data, feedback),
        pristine: flip(chainFun),
        unchecked: flip(chainFun),
        valid: flip(chainFun),
      },
      formField
    )
  ),

  map: curry((mapFun, formField) =>
    FormField.case(
      {
        invalid: (data, feedback, meta) =>
          FormField.invalid(feedback, meta, mapFun(data)),
        pristine: (data, meta) => FormField.pristine(meta, mapFun(data)),
        unchecked: (data, meta) => FormField.unchecked(meta, mapFun(data)),
        valid: (data, meta) => FormField.valid(meta, mapFun(data)),
      },
      formField
    )
  ),

  getFeedback: prop("feedback"),

  getMeta: prop("meta"),

  getValue: prop("value"),

  validate: (formField) => {
    const meta = FormField.getMeta(formField) || Meta.create(null, "");
    const fieldValue = FormField.getValue(formField);

    const validFormField = FormField.isValid(formField)
      ? formField
      : FormField.chain(FormField.valid, formField);

    const makeInvalid = (rule, message) =>
      FormField.chain(
        FormField.invalid({
          feedbackMessageId: message,
          hasError: true,
          rule,
        }),
        formField
      );

    return meta.validations.length === 0
      ? validFormField
      : meta.validations.reduce((acc, [validation, rule]) => {
          const invalidFormField = makeInvalid(rule, rule.message);
          const value = rule.path ? fieldValue[rule.path] : fieldValue;

          if (FormField.isInvalid(acc)) {
            return acc;
          }

          switch (validation) {
            case VALIDATIONS.REQUIRED:
              return value ? validFormField : invalidFormField;

            case VALIDATIONS.PATTERN:
              return RegExp(`^(?:${rule.match})$`).test(value)
                ? validFormField
                : invalidFormField;

            case VALIDATIONS.GROUP_REQUIRED: {
              const anyTrue = Object.values(fieldValue).some((v) =>
                rule.path ? v[rule.path] === true : v === true
              );

              return anyTrue ? validFormField : invalidFormField;
            }

            case VALIDATIONS.IN_DATASET:
              return rule.dataset[value] ? validFormField : invalidFormField;

            case VALIDATIONS.NUMBER_RANGE:
              return parseFloat(value) > rule.min &&
                parseFloat(value) < rule.max
                ? validFormField
                : makeInvalid(
                    rule,
                    parseFloat(value) <= rule.min
                      ? rule.minMessage
                      : rule.maxMessage
                  );

            default:
              return validFormField;
          }
        }, validFormField);
  },
};

export default FormField;

export { Meta, Validation };
