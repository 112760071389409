import React, { Fragment } from "react";
import { Headline, Link, Text, THEME } from "@dhl-official/react-ui-library";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import LabelFlag from "../../LabelFlag";
import SmallShare from "./SmallShare";
import COPY from "../../../assets/copy/index.json";
import { prependWithBaseURL, sortByCountry } from "../../../utils";
import { ROLLOUT_TRACKS } from "../../../utils/constants";

const getTrackBackgroundColor = (track) => {
  switch (track) {
    case ROLLOUT_TRACKS.DHL_COM:
      return THEME.getPrimaryColor("400");

    case ROLLOUT_TRACKS.DIGITAL_EXPERIENCE_PLATFORM:
      return THEME.getSuccessColor("400");

    case ROLLOUT_TRACKS.CUSTOMER_JOURNEYS:
    default:
      return THEME.getSecondaryColor("400");
  }
};

// #region Component Styles
const Container = styled.div`
  min-width: 200px;
  margin: ${THEME.getUnit("regular")};
  max-width: 600px;
  position: relative;

  &:before {
    content: " ";
    background-color: ${({ $trackColor }) =>
      getTrackBackgroundColor($trackColor)};
    height: 100%;
    left: -14px;
    position: absolute;
    top: 0;
    width: 4px;
  }
`;

const Title = styled(Headline).attrs({
  designLevel: 4,
  tag: Headline.H4,
})`
  font-weight: 400;
  margin: 0;
  margin-bottom: ${THEME.getUnit("regular")};
`;

const TextWithMargin = styled(Text).attrs({ isParagraph: true })`
  margin: 0 auto ${THEME.getUnit("regular")};
`;

const TextWithoutMargin = styled(Text).attrs({ isParagraph: true })`
  margin: 0;
`;

const Label = styled(Text).attrs({
  isParagraph: false,
  weight: 700,
})``;

const MarketList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Market = styled.li`
  display: flex;
  align-items: center;
`;

const StyledLink = styled(Link).attrs({
  rel: "noopener noreferrer",
  target: "_blank",
})`
  margin: ${THEME.getUnit("regular")} auto;
`;
// #endregion

const DATE_FORMAT = "dd.MM.Y";

const getCompleteShareUrl = (path, detailLink) =>
  prependWithBaseURL(`${path}?detail=${detailLink}`);

const ProjectOverviewDetails = ({ entry }) => {
  const {
    contact,
    description,
    detailLink,
    endDate,
    fullName,
    languages,
    link,
    markets,
    startDate,
    track,
  } = entry;
  const location = useLocation();

  return (
    <Container $trackColor={track}>
      <Title>{fullName}</Title>
      {description && (
        <TextWithMargin>
          {" "}
          <FormattedMessage
            defaultMessage={description}
            id={description}
            values={{ br: <br /> }}
          />
        </TextWithMargin>
      )}
      <TextWithoutMargin>
        <Label>Dates:</Label> {format(startDate, DATE_FORMAT)} -{" "}
        {format(endDate, DATE_FORMAT)}
      </TextWithoutMargin>
      {markets && Array.isArray(markets) && markets.length > 0 ? (
        <Fragment>
          <TextWithoutMargin>
            <Label>Markets:</Label>
            {markets.length > 1 && (
              <Fragment> {markets.length} markets</Fragment>
            )}
          </TextWithoutMargin>

          <MarketList>
            {markets.sort(sortByCountry()).map((country) => (
              <Market key={country}>
                <LabelFlag country={country} />
              </Market>
            ))}
          </MarketList>
        </Fragment>
      ) : (
        markets && (
          <TextWithoutMargin>
            <Label>Markets:</Label> {markets}
          </TextWithoutMargin>
        )
      )}
      {languages && Array.isArray(languages) && languages.length > 0 && (
        <TextWithoutMargin>
          <Label>Languages:</Label>{" "}
          {languages
            .map((item) => COPY[`Language.${item}`])
            .join(languages.length > 2 ? ", " : " & ")}
        </TextWithoutMargin>
      )}
      {contact && (
        <TextWithoutMargin>
          <Label>Point of contact:</Label> {contact}
        </TextWithoutMargin>
      )}
      {link && (
        <TextWithMargin>
          <Label>More information:</Label>{" "}
          <StyledLink href={link}>{link}</StyledLink>
        </TextWithMargin>
      )}
      {detailLink && (
        <SmallShare url={getCompleteShareUrl(location.pathname, detailLink)} />
      )}
    </Container>
  );
};

ProjectOverviewDetails.propTypes = {
  entry: PropTypes.object.isRequired,
};

export default ProjectOverviewDetails;
