import {
  CLOSE_MODAL,
  OPEN_CJ_MAP_REGION_DATA_MODAL,
  OPEN_EDIT_BUSINESS_UNIT_MODAL,
  OPEN_HOMEPAGE_MAP_REGION_DATA_MODAL,
  OPEN_SHARE_MODAL,
  OPEN_DELETE_GAQ_RELEASE_NOTE_MODAL,
  OPEN_PROJECT_OVERVIEW_DETAILS_MODAL,
  SET_BUSINESS_UNIT_OPTIONS,
  SET_BUSINESS_UNIT_THRESHOLD_ENABLED,
  SET_BUSINESS_UNIT_THRESHOLD,
  UPDATE_BUSINESS_UNIT,
} from "../../actions";

export const openCJMapRegionDataModal = (url) => ({
  type: OPEN_CJ_MAP_REGION_DATA_MODAL,
  payload: url,
});

export const openHomepageMapRegionDataModal = (url) => ({
  type: OPEN_HOMEPAGE_MAP_REGION_DATA_MODAL,
  payload: url,
});

export const openEditBusinessUnitModal = (identity, threshold, urgency) => ({
  type: OPEN_EDIT_BUSINESS_UNIT_MODAL,
  payload: { identity, threshold, urgency },
});

export const setBusinessUnitUrgency = (payload) => ({
  payload,
  type: SET_BUSINESS_UNIT_OPTIONS,
});

export const updateBusinessUnit = (identity, threshold, options) => ({
  payload: {
    identity,
    threshold,
    options,
  },
  type: UPDATE_BUSINESS_UNIT,
});

export const setBusinessUnitThresholdEnabled = (payload) => ({
  payload,
  type: SET_BUSINESS_UNIT_THRESHOLD_ENABLED,
});

export const setBusinessUnitThreshold = (name, value) => ({
  payload: [name, value],
  type: SET_BUSINESS_UNIT_THRESHOLD,
});

export const openShareModal = (url) => ({
  type: OPEN_SHARE_MODAL,
  payload: url,
});

export const openDeleteReleaseNoteModal = (data) => ({
  type: OPEN_DELETE_GAQ_RELEASE_NOTE_MODAL,
  payload: data,
});

export const openProjectOverviewDetailsModal = (payload) => ({
  type: OPEN_PROJECT_OVERVIEW_DETAILS_MODAL,
  payload,
});

export const closeModal = () => ({
  type: CLOSE_MODAL,
});
