import { ROUTES } from "../../../../utils/constants";
import {
  SET_HOMEPAGE_MAP_DISPLAY_MODE,
  SET_HOMEPAGE_MAP_REGION,
  SET_HOMEPAGE_ROLLOUT_VERSION_FILTER_EFFECT,
  TOGGLE_HOMEPAGE_ROLLOUT_STATUS_FILTER,
} from "../../../../actions";

export const setHomepageMapDisplayMode = (payload) => ({
  type: SET_HOMEPAGE_MAP_DISPLAY_MODE,
  payload,
});

export const setHomepageMapRegion = (payload) => ({
  type: SET_HOMEPAGE_MAP_REGION,
  payload,
});

export const setHomepageRolloutVersionFilterEffect =
  (payload) =>
  (dispatch, _, { history }) => {
    dispatch({ type: SET_HOMEPAGE_ROLLOUT_VERSION_FILTER_EFFECT });

    history.push(`${ROUTES.HOMEPAGE_ROLLOUT_OVERVIEW}/${payload}`);
  };

export const toggleHomepageRolloutStatusFilter = (payload) => ({
  payload,
  type: TOGGLE_HOMEPAGE_ROLLOUT_STATUS_FILTER,
});
