import { propEq, curry, prop } from "ramda";

const AUTO_COMPLETE_TYPE = "AutoComplete";

const AUTO_COMPLETE_DATA_TAG = {
  SELECTED: "Selected",
  UNSELECTED: "UnSelected",
};

const NOOP = Function.prototype;

const AutoComplete = {
  unselected: (value = "", options = []) => ({
    options,
    tag: AUTO_COMPLETE_DATA_TAG.UNSELECTED,
    type: AUTO_COMPLETE_TYPE,
    value,
  }),

  selected: (value) => ({
    tag: AUTO_COMPLETE_DATA_TAG.SELECTED,
    type: AUTO_COMPLETE_TYPE,
    value,
  }),

  case: curry((caseObj, autoComplete) => {
    const { unselected, selected, _ = NOOP } = caseObj;

    if (selected && AutoComplete.isSelected(autoComplete)) {
      return selected(autoComplete.value);
    }

    if (unselected && AutoComplete.isUnselected(autoComplete)) {
      return unselected(autoComplete.value, autoComplete.options);
    }

    return _();
  }),

  isUnselected: propEq("tag", AUTO_COMPLETE_DATA_TAG.UNSELECTED),

  isSelected: propEq("tag", AUTO_COMPLETE_DATA_TAG.SELECTED),

  isAutoComplete: propEq("type", AUTO_COMPLETE_TYPE),

  getValue: prop("value"),
};

export default AutoComplete;
